<template>
  <div class="class-list">
    <el-card>
      <div class="list">
        <div class="left-box">
          <div class="name">选择学院</div>
        </div>
        <div class="right-box">
          <template v-for="(v, i) in list" :key="i">
            <div class="li cursor-pointer" @click="setActive(0, { id: v.id, index: i })" :class="{ on: i == activeArr[0].index }" v-if="!!v.children.length">
              {{ v.name }}
            </div>
          </template>
        </div>
      </div>
      <div class="list project" :style="{ 'max-height': isProjectMore ? projectHeight + 'px' : '60px' }" v-if="tree2.length" ref="projectRef">
        <div class="left-box">
          <div class="name">选择项目</div>
        </div>
        <div class="right-box" ref="projectItemRef">
          <div class="li cursor-pointer" v-for="(v, i) in tree2" @click="setActive(1, { id: v.id, index: i, name: v.name })" :class="{ on: i == activeArr[1].index }">
            {{ v.name }}
          </div>
        </div>
        <div class="more cursor-pointer" @click="projectMoreClick" v-if="projectItemHeight > 34">
          <span>{{ isProjectMore ? '收起' : '展开' }}</span>
          <el-icon class="el-collapse-item__arrow " :class="isProjectMore ? 'is-active' : ''">
            <i-arrow-down></i-arrow-down>
          </el-icon>
        </div>
      </div>
      <div class="list" v-if="tree2.length">
        <div class="left-box">
          <div class="name">选择科目</div>
        </div>
        <div class="right-box">
          <div class="li cursor-pointer" v-for="(v, i) in tree3" @click="setActive(2, { id: v.id, index: i, name: v.name })" :class="{ on: i == activeArr[2].index }">
            {{ v.name }}
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { poll, debounce } from '@/utils/index.js'
import { classPath } from '@/utils/business.js'
import { mapState } from 'vuex'
export default {
  props: {
    id: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      activeArr: [
        {
          id: '',
          index: 0
        },
        {
          id: '',
          index: 0
        },
        {
          id: '',
          index: 0
        }
      ],
      isProjectMore: false,
      projectItemHeight: 0,
      projectHeight: 0,
      isAddMargin: false
    }
  },
  watch: {
    id() {
      this.init()
    }
  },
  created() {
    this.init()
  },
  mounted() {
    this.$nextTick(() => {
      this.projectHeight = this.$refs.projectRef.clientHeight
      this.projectItemHeight = this.$refs.projectItemRef.clientHeight
    })
  },
  computed: {
    ...mapState({
      list: state => state.classList
    }),
    tree2() {
      return this.list[this.activeArr[0].index] ? this.list[this.activeArr[0].index].children : []
    },
    tree3() {
      return this.tree2[this.activeArr[1].index] ? this.tree2[this.activeArr[1].index].children : []
    }
  },
  methods: {
    init() {
      //自动轮询查询list数据是否被加载
      poll(
        res => {
          if (this.id) {
            //如果传入id就解析
            classPath(this.list, this.id).forEach((item, index) => {
              this.activeArr[index] = item
              this.setActive(index, {
                id: item.id,
                index: item.index
              })
            })
          } else {
            this.setActive(0, {
              id: this.list[0].id,
              index: 0
            })
          }
        },
        () => {
          return this.list.length
        }
      )
    },
    setActive(b, val) {
      this.activeArr[b] = val
      this.activeArr.forEach((item, i) => {
        if (b < i) {
          if (i == 1) {
            this.activeArr[i] = {
              id: this.tree2[0].id,
              index: 0
            }
          }
          if (i == 2) {
            this.activeArr[i] = {
              id: this.tree3[0].id,
              index: 0
            }
          }
        }
      })
      this.$emit('change', this.activeArr)

      if (this.isProjectMore) {
        this.$nextTick(() => {
          this.projectHeight = this.$refs.projectRef.style['max-height'].replace('px', '')
          this.projectItemHeight = this.$refs.projectItemRef.clientHeight
        })
      } else {
        this.$nextTick(() => {
          this.projectHeight = this.$refs.projectRef.clientHeight
          this.projectItemHeight = this.$refs.projectItemRef.clientHeight
        })
      }
    },
    projectMoreClick() {
      this.isProjectMore = !this.isProjectMore
      if (this.isProjectMore) {
        this.projectHeight = this.$refs.projectRef.style['max-height'].replace('px', '')
        this.projectHeight = this.projectHeight + 20
      } else {
        this.projectHeight = this.projectHeight - 20
      }
    }
  }
}
</script>

<style scoped lang="scss">
.class-list {
  padding-top: 20px;

  :deep .el-card {
    border-radius: 0;

    .list + .list {
      border-top: 1px solid #eeeeee;
    }
    .project {
      position: relative;
      max-height: 65px;
      //   transition: 0.5s all;
      overflow: hidden;
    }
    .list {
      display: flex;
      padding: 13px 10px 7px 0;

      .left-box {
        flex: 0 0 auto;

        .name {
          font-size: 14px;

          font-weight: 400;
          color: #999999;
          line-height: 24px;
          border-right: 1px solid #e5e5e5;
          padding-right: 12px;
          line-height: 14px;
          margin-top: 8px;
        }
      }

      .right-box {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin-right: 50px;
        .li {
          height: 28px;
          border-radius: 14px;
          margin-left: 20px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 13px;
          font-size: 15px;

          font-weight: 400;
          color: #333333;
          margin-bottom: 5px;

          &.on,
          &:hover {
            background: #e7edfd;
            color: $primary-color;
          }
        }
      }
      .more {
        display: flex;
        align-items: center;
        position: absolute;
        top: 0;
        right: 0;
        margin-top: 17px;
        font-size: 12px;
        color: #888888;
        span {
          padding-right: 5px;
        }
      }
      .is-active {
        transform: rotate(-180deg);
      }
    }
  }
  :deep .el-card.is-always-shadow {
    box-shadow: 0px 4px 9px 0px rgba(11, 42, 113, 0.03);
  }
}
</style>

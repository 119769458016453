<template>
  <div @click.stop="getCode" class="cursor-pointer">
    <slot :showText="showText">{{ showText }}</slot>
  </div>
</template>
<script>
//获取验证码
import { getCode } from '@/api/index.js'
import { mapState } from 'vuex'
export default {
  props: {
    // 需要向后台发送的数据
    formData: {
      type: Object,
      default() {
        return {}
      }
    },
    // 发送前文字和发送后文字 [/time] 倒计时时间
    textArr: {
      type: Array,
      default() {
        return ['获取验证码', '再次发送[/time]']
      }
    },
    // 发送什么类型的验证码可能会用到暂时不实现功能
    type: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      isClick: true
    }
  },
  watch: {
    codeTime(newV) {
      if (newV !== 60) {
        this.isClick = false
      } else {
        this.isClick = true
      }
    }
  },
  computed: {
    ...mapState(['codeTime']),
    showText() {
      if (this.codeTime !== 60) {
        return this.textArr[1] ? this.textArr[1].replace(/\[\/time\]/, this.codeTime) : `再次发送${this.codeTime}`
      }
      return this.textArr[0] || '发送验证码'
    }
  },
  methods: {
    getCode() {
      if (!this.isClick) return
	  getCode(this.formData).then(() => {
		  this.$store.dispatch('codeTime')
	  }).catch()
    }
  }
}
</script>

<style scoped></style>

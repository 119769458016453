<template>
  <el-config-provider :locale="locale" size="large">
    <router-view v-slot="{ Component }">
      <!-- vue3.0配置 keep-alive缓存-->
      <keep-alive>
        <component :is="Component" v-if="$route.meta.keepAlive" :key="$route.path" />
      </keep-alive>
      <component :is="Component" v-if="!$route.meta.keepAlive" />
    </router-view>
  </el-config-provider>
</template>

<style lang="scss">
.w1200 {
  width: 1200px;
  margin: 0 auto;
}
</style>
<script type="text/javascript">
import { defineComponent } from 'vue'
import zhCn from 'element-plus/lib/locale/lang/zh-cn'
export default defineComponent({
  data() {
    return {
      locale: zhCn
    }
  },
  created() {
    // this.$store.dispatch('getClassList') //获取分类数据
    this.$store.dispatch('getUserInfo') //更新用户信息
    this.$store.dispatch('getClassList') //获取分类信息
    this.$store.dispatch('getUserDict') //获取用户字典
  }
})
</script>

import request from '@/utils/request'
import { fetchRequest } from '@/utils/request'
const PROXY = process.env.NODE_ENV === 'development' ? '/api' : '/apis'
//密码登录
export function loginPwd(data) {
  return request({
    url: `${PROXY}/coursera/common/user/login/pwd`,
    method: 'post',
    data
  })
}
//三方账号绑定
export function thirdRelation(data) {
  return request({
    url: `${PROXY}/coursera/common/user/third/relation`,
    method: 'post',
    data
  })
}
//微信扫码登录code 换取用户信息
export function getUserInfoByWeChatCode(data) {
  return request({
    url: `${PROXY}/coursera/common/user/third/web`,
    method: 'get',
    params: data
  })
}

//验证码登录
export function loginCode(data) {
  return request({
    url: `${PROXY}/coursera/common/user/login/code`,
    method: 'post',
    data
  })
}

//查看sass列表
export function findSass(data) {
  return request({
    url: `${PROXY}/coursera/user/find/sass`,
    method: 'get',
    params: data
  })
}

//切换租户
export function changeSass(data) {
  return request({
    url: `${PROXY}/coursera/user/change/sass`,
    method: 'get',
    params: data
  })
}

//获取默认sass
export function getDefaultSass(data) {
  return request({
    url: `${PROXY}/coursera/user/default/sass`,
    method: 'get',
    params: data
  })
}

//获取用户选择的科目
export function memberSelected(data) {
  return request({
    url: `${PROXY}/coursera/member/classification/selected/list`,
    method: 'get',
    params: data
  })
}
//更新用户选择的科目
export function memberSelectedUpdate(data) {
  return request({
    url: `${PROXY}/coursera/member/classification/selected/update`,
    method: 'post',
    data
  })
}
//扫码登录获取url
export function getScanToken(data) {
  return request({
    url: `${PROXY}/coursera/common/user/scan/token`,
    method: 'get',
    params: data
  })
}
//获取扫码登录成功状态
export function getLoginqrCode(data) {
  return request({
    url: `${PROXY}/coursera/common/user/login/qrCode`,
    method: 'get',
    params: data
  })
}
//获取验证码
export function getCode(data) {
  return request({
    url: `${PROXY}/coursera/common/user/send/msg`,
    method: 'post',
    data
  })
}
//注册
export function setRegister(data) {
  return request({
    url: `${PROXY}/coursera/common/user/register`,
    method: 'post',
    data
  })
}
//忘记密码
export function retrievePwd(data) {
  return request({
    url: `${PROXY}/coursera/common/user/retrieve/pwd`,
    method: 'post',
    data
  })
}
//换绑手机号
export function changePhone(data) {
  return request({
    url: `${PROXY}/coursera/user/change/phone`,
    method: 'get',
    params: data
  })
}
//交换新token
export function getNewToken(data) {
  return request({
    url: `${PROXY}/coursera/common/user/refresh/token`,
    method: 'get',
    params: data
  })
}
//获取用户信息
export function getUserInfo(data) {
  return request({
    url: `${PROXY}/coursera/user/info/detail`,
    method: 'get',
    params: data
  })
}
//获取分类
export function getClassList(data) {
  return request({
    url: `${PROXY}/coursera/common/classification/tree3`,
    method: 'get',
    params: data
  })
}
//获取公开课直播
export function todayLive(data) {
  return request({
    url: `${PROXY}/coursera/open/today/live`,
    method: 'post',
    data
  })
}
//获取近期直播
export function recentVideolist(data) {
  return request({
    url: `${PROXY}/coursera/open/live/list`,
    method: 'post',
    data
  })
}
//获取热门录播
export function videoList(data) {
  return request({
    url: `${PROXY}/coursera/open/video/list`,
    method: 'post',
    data
  })
}
//课程分类列表
export function classifyList(data) {
  return request({
    url: `${PROXY}/coursera/common/classification/course/classify/list`,
    method: 'get',
    params: data
  })
}

//课程视频状态
export function videoState(data) {
  return request({
    url: `${PROXY}/coursera/course/video/state/${data.id}`,
    method: 'get'
  })
}

//课程详情
export function videoDetail(data) {
  return request({
    url: `${PROXY}/coursera/course/video/detail/${data.id}?openPage=${data.openPage}`,
    method: 'get'
  })
}
//直播详情
export function liveDetail(data) {
  return request({
    url: `${PROXY}/coursera/live/detail/${data.id}`,
    method: 'get'
  })
}
//获取微吼直播初始化值
export function liveInit(data) {
  return request({
    url: `${PROXY}/coursera/live/init/param/${data.id}`,
    method: 'post'
  })
}
//课件列表
export function coursewareList(data) {
  return request({
    url: `${PROXY}/coursera/course/courseware/list`,
    method: 'post',
    data
  })
}
//课件下载次数统计
export function coursewareDownload(data) {
  return request({
    url: `${PROXY}/coursera/course/courseware/download`,
    method: 'post',
    data
  })
}
//获取播放凭证和获取播放地址基本一样
export function playAuth(data) {
  return request({
    url: `${PROXY}/coursera/course/play/auth`,
    method: 'post',
    data
  })
}
//获取课程小结的播放地址
export function playInfo(data) {
  return request({
    url: `${PROXY}/coursera/course/play/info/${data.id}`,
    method: 'get'
  })
}
//班课列表
export function moneyClassList(data) {
  return request({
    url: `${PROXY}/coursera/common/classes/list`,
    method: 'post',
    data
  })
}
//班课详情
export function moneyClassesDetail(data) {
  return request({
    url: `${PROXY}/coursera/common/classes/detail/${data.id}`,
    method: 'get'
  })
}
//获取班课所有讲师
export function lecturerList(data) {
  return request({
    url: `${PROXY}/coursera/common/classes/lecturer/list`,
    method: 'post',
    data
  })
}
//班课下的所有课程书
export function classesCourseTree(data) {
  return request({
    url: `${PROXY}/coursera/common/classes/course/tree`,
    method: 'post',
    data
  })
}
//添加或者编辑地址
export function addressSave(data) {
  return request({
    url: `${PROXY}/coursera/address/save`,
    method: 'post',
    data
  })
}
//领取优惠券
export function couponBy(data) {
  return request({
    url: `${PROXY}/coursera/coupon/list/by/${data.id}`,
    method: 'post'
  })
}
//获取公告
export function notifiNoticeList(data) {
  return request({
    url: `${PROXY}/coursera/notifiNotice/list`,
    method: 'post',
    data
  })
}
//获取课程的面授课程列表
export function listeningFindTree2(data) {
  return request({
    url: `${PROXY}/coursera/listening/find/tree2`,
    method: 'post',
    data
  })
}

//获取地址列表
export function addressList(data) {
  return request({
    url: `${PROXY}/coursera/address/list`,
    method: 'post',
    data
  })
}
//重复下单校验
export function checkOrder(data) {
  return request({
    url: `${PROXY}/coursera/order/check/order`,
    method: 'post',
    data
  })
}

//下单
export function submitOrders(data) {
  return request({
    url: `${PROXY}/coursera/order/save/order`,
    method: 'post',
    data
  })
}
//订单详情
export function orderDetail(data) {
  return request({
    url: `${PROXY}/coursera/order/${data.id}`,
    method: 'get'
  })
}
//支付
export function payOrder(data) {
  return request({
    url: `${PROXY}/coursera/order/pay/order`,
    method: 'post',
    data
  })
}
//获取各种协议
export function getProtocol(data) {
  return request({
    url: `${PROXY}/coursera/common/protocol/list/${data.type}/${data.corpId}`,
    method: 'get'
  })
}
//oss鉴权信息
export function OSSSTS(data) {
  return request({
    url: `${PROXY}/coursera/file/oss/sts`,
    method: 'get',
    params: data
  })
}
//申请发票
export function invoiceSave(data) {
  return request({
    url: `${PROXY}/coursera/invoice/save`,
    method: 'post',
    data
  })
}

//获取问题反馈类型
export function feedbackTypeList(data) {
  return request({
    url: `${PROXY}/coursera/feedback/typeList`,
    method: 'post',
    data
  })
}
//提交问题反馈
export function feedbackSave(data) {
  return request({
    url: `${PROXY}/coursera/feedback/save`,
    method: 'post',
    data
  })
}

//自己写的接口
//订单列表
export function MyorderIndexList(data) {
  return request({
    url: `${PROXY}/coursera/order/find/page`,
    method: 'post',
    data
  })
}
//听课记录列表
export function StudyCenterCourseHistoryList(data) {
  return request({
    url: `${PROXY}/coursera/listening/record/list`,
    method: 'post',
    data
  })
}
//获取发票列表
export function InvoiceList(data) {
  return request({
    url: `${PROXY}/coursera/invoice/list`,
    method: 'post',
    data
  })
}
//我的收据
export function ReceiptList(data) {
  return request({
    url: `${PROXY}/coursera/receipt/list`,
    method: 'post',
    data
  })
}
//获取课程下课程分类

export function studyClassifyList(data) {
  return request({
    url: `${PROXY}/coursera/study/course/classify/list`,
    method: 'get',
    params: data
  })
}
//获取地址列表
export function getAddressList(data) {
  return request({
    url: `${PROXY}/coursera/address/list`,
    method: 'post',
    data
  })
}
//删除订单
export function myorderdelect(data) {
  return request({
    url: `${PROXY}/coursera/order/delete/${data.id}`,
    method: 'get'
  })
}
//订单详情
export function myorderdetail(data) {
  return request({
    url: `${PROXY}/coursera/order/${data.id}`,
    method: 'get'
  })
}
//列表展示物流信息
export function myorderLogistics(data) {
  return request({
    url: `${PROXY}/coursera/order/logistics/info`,
    method: 'post',
    data
  })
}
//课程列表
export function courseqaList(data) {
  return request({
    url: `${PROXY}/coursera/learn/questions/course/list`,
    method: 'post',
    data
  })
}
//我的直播
export function StudyCenterMyVlogList(data) {
  return request({
    url: `${PROXY}/coursera/live/list`,
    method: 'post',
    data
  })
}
//我的直播回放
export function livePlaybackList(data) {
  return request({
    url: `${PROXY}/coursera/live/playback/list`,
    method: 'post',
    data
  })
}
//删除地址
export function deleteAddress(data) {
  return request({
    url: `${PROXY}/coursera/address/delete/${data.id}`,
    method: 'get'
  })
}
//我购买的项目列表
export function StudyCenterStartCourseList(data) {
  return request({
    url: `${PROXY}/coursera/study/classify/list`,
    method: 'get',
    params: data
  })
}
//我的班课列表
export function StudyCenterStartCourseData(data) {
  return request({
    url: `${PROXY}/coursera/study/classes/list`,
    method: 'post',
    data
  })
}

//配套资料
export function materialList(data) {
  return request({
    url: `${PROXY}/coursera/listening/material/list`,
    method: 'post',
    data
  })
}

//配套资料过滤条件
export function materialListFilter(data) {
  return request({
    url: `${PROXY}/coursera/listening/material/type/list`,
    method: 'post',
    data
  })
}
//班课下图书列表
export function classesBookList(data) {
  return request({
    url: `${PROXY}/coursera/common/classes/book/list`,
    method: 'post',
    data
  })
}
//开始听课————班型章节
export function StudyCenterStartCourseClass(data) {
  return request({
    url: `${PROXY}/coursera/listening/find/tree`,
    method: 'post',
    data
  })
}

//开始听课————班型章节 最新版改版
export function learnStudy(data) {
  return request({
    url: `${PROXY}/coursera/study/find/tree`,
    method: 'post',
    data
  })
}

//获取班课视频科目列表
export function getStudentSubjectData(data) {
  return request({
    url: `${PROXY}/coursera/study/subject/list?studentId=${data.studentId}`,
    method: 'get'
  })
}

//学习-直播分组列表
export function listeningLiveGroup(data) {
  return request({
    url: `${PROXY}/coursera/study/live/group/list`,
    method: 'post',
    data
  })
}

//保存听课记录
export function listeningRecordSave(data) {
  return request({
    url: `${PROXY}/coursera/listening/record/save`,
    method: 'post',
    data
  })
}
//删除听课记录
export function recordRecord(data) {
  return request({
    url: `${PROXY}/coursera/listening/record/delete/${data.id}`,
    method: 'get'
  })
}
//课程答疑提问
export function CourseQALists(data) {
  return request({
    url: `${PROXY}/coursera/questions/create`,
    method: 'post',
    data
  })
}
// 获取答疑问题类型
export function questionsTypeList(data) {
  return request({
    url: `${PROXY}/coursera/questions/type/list`,
    method: 'get'
  })
}
//课程d答疑列表
export function liebiaoList(data) {
  return request({
    url: `${PROXY}/coursera/questions/list`,
    method: 'post',
    data
  })
}
//课程答疑详情列表
export function courseqaDetails(data) {
  return request({
    url: `${PROXY}/coursera/questions/detail/${data.id}`,
    method: 'get'
  })
}
//用户信息
export function studyCenterSDetails(data) {
  return request({
    url: `${PROXY}/coursera/user/info/detail`,
    method: 'get',
    params: data
  })
}
//修改用户信息
export function userInfoSave(data) {
  return request({
    url: `${PROXY}/coursera/user/info/save`,
    method: 'post',
    data
  })
}
//修改密码时 验证码验证
export function studyCenterSp(data) {
  return request({
    url: `${PROXY}/coursera/common/user/check/code`,
    method: 'post',
    data
  })
}
//修改新旧密码

export function studyCenterSd(data) {
  return request({
    url: `${PROXY}/coursera/common/user/retrieve/pwd`,
    method: 'post',
    data
  })
}
//帮助中心请求列表
export function helperList(data) {
  return request({
    url: `${PROXY}/coursera/helper/common/list`,
    method: 'get',
    params: data
  })
}
//帮助中心详情
export function helperDetail(data) {
  return request({
    url: `${PROXY}/coursera/helper/common/find`,
    method: 'get',
    params: data
  })
}
//获取消息列表
export function notifiMessageList(data) {
  return request({
    url: `${PROXY}/coursera/notifiMessage/list`,
    method: 'post',
    data
  })
}
//消息列表已读接口
export function notifiMessageRead(data) {
  return request({
    url: `${PROXY}/coursera/notifiMessage/read?id=${data.id}`,
    method: 'get'
  })
}
//通知已读接口
export function notifyRead(data) {
  return request({
    url: `${PROXY}/coursera/notifiNotice/read?id=${data.id}`,
    method: 'get'
  })
}

//通知一键已读
export function allNotifyRead(data) {
  return request({
    url: `${PROXY}/coursera/notifiNotice/batch/${data.ids}`,
    method: 'post',
    data
  })
}

//消息一键已读
export function allMessageRead(data) {
  return request({
    url: `${PROXY}/coursera/notifiMessage/batch/${data.ids}`,
    method: 'post',
    data
  })
}

/*****************题库功能API开始*********************/

//章节练习
export function getChapterList(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/examPaper/0/list`,
    method: 'post',
    data
  })
}

//历年真题
export function getRealList(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/examPaper/1/list`,
    method: 'post',
    data
  })
}

//模拟试卷
export function getMockList(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/examPaper/2/list`,
    method: 'post',
    data
  })
}

//月测试
export function getMonthExamList(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/examPaper/5/list`,
    method: 'post',
    data
  })
}

//周测试
export function getWeekExamList(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/examPaper/6/list`,
    method: 'post',
    data
  })
}
export function getTestExamList(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/examPaper/9/list`,
    method: 'post',
    data
  })
}
//课后训练
export function getAfterClassList(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/examPaper/10/list`,
    method: 'post',
    data
  })
}

//根据课程id获取试卷列表
export function examListByCourseId(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/examPaper/listByCourseId`,
    method: 'post',
    data
  })
}
//每日一练
export function getDaysList(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/examPaper/3/list`,
    method: 'post',
    data
  })
}

//易错题
export function getEasyErrorList(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/examPaper/4/list`,
    method: 'post',
    data
  })
}

//错题集试题收藏 查看解析
export function getResolution(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/AnswerRecord/getResolution`,
    method: 'post',
    data
  })
}

//考后估分
export function getEstimateList(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/examPaper/7/list`,
    method: 'post',
    data
  })
}

//做题记录
export function getExamRecordList(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/AnswerRecord/list`,
    method: 'post',
    data
  })
}

//试卷详情预览
export function getExamPreview(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/examPaper/detail/${data.id}`,
    method: 'post'
  })
}
//试卷详情
export function getExamInfo(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/AnswerRecord/getInfo`,
    method: 'post',
    data
  })
}

//开始做题，重新做题时 需要 初始化试卷
export function initExam(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/AnswerRecord/initPaper`,
    method: 'post',
    data
  })
}

//交卷
export function submitExam(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/AnswerRecord/savePaper`,
    method: 'post',
    data
  })
}

//保存进度
export function saveExam(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/AnswerRecord/savePaperRecord`,
    method: 'post',
    data
  })
}

//上一题下一题查看答案
export function saveQuestion(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/AnswerRecord/viewAnswer`,
    method: 'post',
    data
  })
}

//试题收藏
export function collectQuestion(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/QuestionCollect/add`,
    method: 'post',
    data
  })
}

//清空试题收藏
export function clearQuestionCollect(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/QuestionCollect/clear`,
    method: 'post',
    data
  })
}

//试题纠错
export function addQuestionError(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/QuestionAmend`,
    method: 'post',
    data
  })
}
//试题收藏分组
export function getCollectGroup(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/QuestionCollect/list/group`,
    method: 'post',
    data
  })
}

//试题收藏下科目列表
export function getCollectSubjectList(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/QuestionCollect/subjectList`,
    method: 'post',
    data
  })
}

//试题收藏分组下的列表
export function getCollectList(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/examPaper/1002/list`,
    method: 'post',
    data
  })
}

//错题集分组
export function getErrorQuestionGroup(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/AnswerQuestionWrongRecord/list/group`,
    method: 'post',
    data
  })
}

//错题集下科目列表
export function getErrorQuestionSubjectList(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/AnswerQuestionWrongRecord/subjectList`,
    method: 'post',
    data
  })
}

//错题分组下的列表数据
export function getErrorQuestionList(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/examPaper/1001/list`,
    method: 'post',
    data
  })
}

//错题删除
export function removeErrorQuestionList(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/AnswerQuestionWrongRecord/dells`,
    method: 'post',
    data
  })
}

//错题清空
export function clearAnswerQuestionWrongRecord(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/AnswerQuestionWrongRecord/clear`,
    method: 'post',
    data
  })
}

//试题收藏错题集分页
export function getPageInfo(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/examPaper/getPageInfo`,
    method: 'post',
    data
  })
}

//自定义打分
export function submitSelfScore(data) {
  return request({
    url: `${PROXY}/cloud/exam-api/AnswerRecord/selfScores`,
    method: 'post',
    data
  })
}
/*****************题库功能API结束*********************/

//获取用户字典
export function getUserDict() {
  return request({
    url: `${PROXY}/coursera/common/dict/all`,
    method: 'get'
  })
}

//下单时获取用户信息
export function getOrderUserInfo(data) {
  return request({
    url: `${PROXY}/coursera/user/find/clue`,
    method: 'post',
    data
  })
}
//判断是否需要签署协议

export function getSignAgreement(data) {
  return request({
    url: `${PROXY}/coursera/order/isSignAgreement`,
    method: 'post',
    data
  })
}

//签协议
export function signAgreement(data) {
  return request({
    url: `${PROXY}/coursera/order/signAgreement`,
    method: 'post',
    data
  })
}

//学习中心列表签署协议-查看协议
export function lookAgreement(data) {
  return request({
    url: `${PROXY}/coursera/study/classes/detail`,
    method: 'post',
    data
  })
}

//查看协议富文本
export function lookAgreementRaw(data) {
  return request({
    url: `${PROXY}/coursera/order/createAgreement`,
    method: 'post',
    data
  })
}
//查看商机
export function findEdu(data) {
  return request({
    url: `${PROXY}/coursera/edu/find`,
    method: 'post',
    data
  })
}

//报考列表
export function eduWorth(data) {
  return request({
    url: `${PROXY}/coursera/edu/worth`,
    method: 'post',
    data
  })
}

//修改商机
export function eduUpdate(data) {
  return request({
    url: `${PROXY}/coursera/edu/update`,
    method: 'post',
    data
  })
}

//班主任
export function getClassTeacher(data) {
  return request({
    url: `${PROXY}//coursera/teacher/list`,
    method: 'post',
    data
  })
}

// 问题反馈列表 最新接口
export function getFeedBackList(data) {
  return request({
    url: `${PROXY}/coursera/feedback/list`,
    method: 'post',
    data
  })
}

// 问题反馈详情 最新接口
export function getFeedBackDetail(data) {
  return request({
    url: `${PROXY}/coursera/feedback/get`,
    method: 'post',
    data
  })
}

// 问题反馈详情 最新接口
export function reptyFeedBack(data) {
  return request({
    url: `${PROXY}/coursera/feedback/repty`,
    method: 'post',
    data
  })
}

//加盟商获取domain
export function sassAuthDomain(data) {
  return request({
    url: `${PROXY}/coursera/sass/home/domain`,
    method: 'post',
    data
  })
}

//fetchRequest 请求接口 网页退出时，保存视频播放进度
export function listeningRecordSaveByFetch(data) {
  return fetchRequest({
    url: `${PROXY}/coursera/listening/record/save`,
    method: 'POST',
    body: data
  })
}
//员工身份核验码查询
export function userCommonCheck(data) {
  return request({
    url: `${PROXY}/coursera/user/common/check`,
    method: 'post',
    data
  })
}

<template>
  <headerDiy></headerDiy>
  <div class="w1200 class-ification">
    <classList ref="classChange" :id="classid" @change="classChange"></classList>
  </div>
  <div class="record-list">
    <div class="w1200">
      <!-- <div class="title-box">
        <h3>全部课程</h3>
        <el-tabs v-model="activeName" style="width: 920px">
          <el-tab-pane v-for="(v, i) in classifyList" :key="i" :label="v.name" :name="v.id"></el-tab-pane>
        </el-tabs>
      </div> -->

      <div class="main-box">
        <div class="list">
          <div
            class="li hvr-shadow"
            :class="{ left: i % 4 != 0 }"
            v-for="(v, i) in videoList"
            :key="i"
            @click="$windowOpen({ path: '/public/classdetails', query: { classId: v.id } })"
          >
            <rend1a2 :info="v"></rend1a2>
          </div>
        </div>
        <zjLoadstate :status="status">
          <template #nodata>
            <div style="text-align: center; margin-top: 20px; color: #999999">
              课程正在录制中
            </div>
          </template>
        </zjLoadstate>
        <div class="page-box" :style="{ visibility: videoList.length ? '' : 'hidden' }">
          <page ref="page" :url="videoListUrl" v-model="videoList" @statusChange="pageStatus"></page>
        </div>
      </div>
    </div>
  </div>
  <calssPopup ref="calssPopup" @change="classPopupChange"></calssPopup>
  <footerDiy theme="dark"></footerDiy>
  <RightFixedBar />
</template>

<script>
import headerDiy from '@/components/header.vue'
import footerDiy from '@/components/footer.vue'
import page from '@/components/page.vue'
import rend1a2 from '@/components/list/rend1a2.vue'
import classList from '@/components/calssList/calssList.vue'
import calssPopup from '@/components/calssList/popup.vue'
import { getLocalStrorage } from '@/utils/storage.js'
import { debounce } from '@/utils/index.js'
import { todayLive, videoList, classifyList } from '@/api/index.js'
export default {
  data() {
    return {
      activeName: '精讲课程',
      classActiveArr: [],
      videoList: [],
      classifyList: [],
      status: 1,
      classid: this.$route.query.classid
    }
  },
  components: {
    headerDiy,
    footerDiy,
    page,
    rend1a2,
    classList,
    calssPopup
  },
  computed: {
    videoListUrl() {
      return `videoList?projectId=${this.classActiveArr[1] ? this.classActiveArr[1].id : ''}&subjectId=${
        this.classActiveArr[2] ? this.classActiveArr[2].id : ''
      }&pageSize=16`
    },
    mapName() {
      if (this.classActiveArr.length) {
        return this.classActiveArr[2].name == '全部' ? this.classActiveArr[1].name : this.classActiveArr[2].name
      } else {
        return ''
      }
    }
  },
  async created() {
    // const { data } = await classifyList()
    // this.classifyList = data
    // this.activeName = this.classifyList[0].id
  },
  mounted() {
    this.isInit()
  },
  activated() {
    this.isInit()
  },
  methods: {
    isInit() {
      if (!getLocalStrorage('activeClass')) {
        this.$refs.calssPopup.open()
      } else {
        this.classid = getLocalStrorage('activeClass').id
      }
    },
    pageStatus(res) {
      this.status = res
    },
    classPopupChange(res) {
      this.$refs.calssPopup.close()
      this.classid = res[res.length - 1].id
    },
    classChange(res) {
      this.classActiveArr = res
    }
  },
  metaInfo() {
    return {
      title: `【中建教育】公开课`,
      meta: [
        {
          name: 'keywords',
          content: `公开课`
        },
        {
          name: 'description',
          content: `公开课`
        }
      ]
    }
  }
}
</script>

<style scoped lang="scss">
:deep .header-box {
  box-shadow: 0px 4px 32px 0px rgba(11, 42, 113, 0.05);
}
.record-list {
  .main-box {
    min-height: 476px;
    padding-top: 40px;
    .page-box {
      display: flex;
      justify-content: center;
      padding-bottom: 50px;
    }

    .list {
      display: flex;
      flex-wrap: wrap;

      .li {
        background-color: #fff;
        width: 282px;
        margin-bottom: 40px;

        &.left {
          margin-left: 24px;
        }
      }
    }
  }

  .title-box {
    display: flex;
    padding-top: 40px;

    h3 {
      font-size: 20px;

      font-weight: bold;
      color: #333333;
      flex: 0 0 auto;
    }

    :deep .el-tabs {
      // width: 500px;
      margin-left: 72px;

      .el-tabs__nav-wrap {
        &::after {
          display: none;
        }
      }
      .el-tabs__item {
        font-size: 16px;
      }
      .el-tabs__nav-next {
        margin-top: 17px;
        margin-right: 2px;
        width: 6px;
        height: 6px;
        border-top: 1px solid #85888e;
        border-right: 1px solid #85888e;
        transform: rotate(45deg);
        i {
          display: none;
        }
      }
    }
  }
}

.img-pic .span-11 {
  margin-left: 300px;
}
</style>

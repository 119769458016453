<template>

  <div class="com-list-1 cursor-pointer">
    <div class="img-box">
      <el-image class="img" :src="info.coverPicture" fit="cover">
      </el-image>
      <div class="tag">共{{info.videoCount}}节课</div>
    </div>
    <div class="back-col">
      <div class="title-name">
        <div class="cl2">{{info.title}}</div>
      </div>
      <div class="name-box">
        <el-image class="img" :src="info.teacherPicture" fit="cover">
          <template #error>
            <img src="@/assets/img/userface.png" class='img'>
          </template>
        </el-image>
        <span class="span" style="flex-shrink:0">讲师</span>
        <span class="span cl1">{{info.teacherName}}</span>
      </div>
      <div class="money-box">
        免费
      </div>
    </div>

  </div>

</template>

<script>
//初始使用位置   公开课列表页
export default {
  props: {
    info: {
      type: Object,
      default() {
        return {
          status: 0
        }
      }
    }
  },
  components: {},

  data() {
    return {}
  }
}
</script>

<style scoped lang="scss">
.com-list-1 {
  .back-col {
    padding: 0 10px 8px 10px;
  }

  .img-box {
    display: flex;
    position: relative;

    .img {
      width: 282px;
      height: 164px;
      border-radius: 4px 4px 0px 0px;
    }

    .tag {
      position: absolute;
      background-color: rgba(0, 0, 0, 0.8);
      line-height: 20px;
      border-radius: 2px;
      font-size: 12px;
      color: #ffffff;
      padding: 0 5px;
      bottom: 9px;
      right: 9px;
    }
  }

  .title-name {
    height: 38px;
    font-size: 14px;
    color: #333333;
    padding-top: 14px;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 14px;
  }

  .name-box {
    display: flex;
    align-items: center;
    padding-top: 16px;

    .img {
      width: 28px;
      height: 28px;
      border-radius: 50%;
    }

    .span {
      font-size: 14px;
      color: #999999;
      margin-left: 15px;
    }

    .span + .span {
      margin-left: 10px;
    }
  }

  .money-box {
    font-size: 18px;
    color: #e73928;
    margin-top: 16px;
  }
}
</style>

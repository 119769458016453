<template>
  <div class="com-list-2 cursor-pointer" @click="$windowOpen({ path: '/elective/details', query: { id: info.id } })">
    <div class="img-box">
      <el-image class="img" :src="info.coverPicture" fit="cover"> </el-image>
    </div>
    <div class="back-col">
      <div class="title-name">
        <div class="cl2">{{ info.name }}</div>
      </div>
      <div class="dex-box">
        <div class="left-info-box">
          <div class="dex-li">
            <span v-html="afterSales" class="cl1" :style="{ width: info.preview == 1 ? '180px' : '260px' }"></span>
          </div>
        </div>
        <div
          class="dex-button"
          v-if="info.preview == 1"
          @click.stop="
            $windowOpen({
              path: '/elective/details',
              query: { id: info.id, current: 1 }
            })
          "
        >
          <i class="iconfont icon-erji"></i>
          试听
        </div>
      </div>
      <template v-if="isDefaultCorpId">
        <div class="money-box">￥{{ info.minPrice }}-{{ info.maxPrice }}</div>
      </template>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { defaultCorpId } from '@/utils/index.js'
//初始使用位置   选课中心列表
export default {
  props: {
    info: {
      type: Object,
      default() {
        return {}
      }
    }
  },
  computed: {
    ...mapState(['corpId']),
    isDefaultCorpId() {
      return this.corpId == defaultCorpId && !this.$store.getters.isSass
    },
    afterSales() {
      return this.info.afterSalesLabels ? this.info.afterSalesLabels.join('&nbsp;&nbsp;|&nbsp;&nbsp;') : ''
    }
  },
  data() {
    return {}
  }
}
</script>

<style scoped lang="scss">
.com-list-2 {
  .back-col {
    padding: 0 10px 8px 10px;
  }

  .dex-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 22px;
    .left-info-box {
      flex: 0.85;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .dex-li {
      font-size: 12px;
      color: #999999;
      display: flex;

      i {
        margin-right: 5px;
      }
    }

    .dex-button {
      width: 70px;
      height: 26px;
      background: linear-gradient(94deg, #ef7761, #e73928);
      border-radius: 13px;
      color: #fff;
      text-align: center;
      line-height: 26px;
      font-size: 14px;

      i {
        margin-right: 6px;
      }
    }
  }

  .img-box {
    display: flex;
    position: relative;

    .img {
      width: 282px;
      height: 164px;
      border-radius: 4px 4px 0px 0px;
    }
  }

  .title-name {
    height: 38px;
    font-size: 14px;
    color: #333333;
    padding-top: 16px;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 14px;
  }

  .money-box {
    font-size: 20px;
    color: #e73928;
    font-weight: bold;
    margin-top: 16px;
  }
}
</style>

import $ from 'jquery'
import store from '@/store'
import { ElMessage } from 'element-plus'
//生成uuid
export function uuid() {
  var s = []
  var hexDigits = '0123456789abcdef'
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  s[14] = '4'
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
  s[8] = s[13] = s[18] = s[23] = '-'
  var uuid = s.join('')
  return uuid
}
//返回变量类型
export function gettype(data) {
  var pro = Object.prototype.toString
  return pro.call(data)
}
/*
 * 获取url字符串参数
 */
export function getRequestParameters(locationhref) {
  let href = locationhref || ''
  let theRequest = new Object()
  let str = href.split('?')[1]
  if (str != undefined) {
    let strs = str.split('&')
    for (let i = 0; i < strs.length; i++) {
      theRequest[strs[i].split('=')[0]] = strs[i].split('=')[1]
    }
  }
  return theRequest
}
/*
 * obj 转 params字符串参数
 * 例子：{a:1,b:2} => a=1&b=2
 */
export function objParseParam(obj) {
  let paramsStr = ''
  if (obj instanceof Array) return paramsStr
  if (!(obj instanceof Object)) return paramsStr
  for (let key in obj) {
    paramsStr += `${key}=${obj[key]}&`
  }
  return paramsStr.substring(0, paramsStr.length - 1)
}
// 对象去空
export function clear(obj) {
  for (const i in obj) {
    if (obj[i] === '' || obj[i] === undefined || obj[i] === 'undefined' || obj[i] === null || obj[i] === 'null') {
      delete obj[i]
    }
  }
  return obj
}
/*
 *反抖
 */
export function debounce(fn, delay) {
  var timer = null
  return function() {
    var context = this
    var args = arguments
    clearTimeout(timer)
    timer = setTimeout(function() {
      fn.apply(context, args)
    }, delay)
  }
}
//轮询封装
export function poll(callback, verifyFn, interval = 1000) {
  if (verifyFn()) {
    callback()
  } else {
    setTimeout(function() {
      poll(callback, verifyFn, interval)
    }, interval)
  }
}
//手机号脱敏
export function desens(str) {
  var pat = /(\d{3})\d*(\d{4})/
  return str.replace(pat, '$1****$2')
}
/*
 * 时间戳转时间返回单独分开的对象
 * Y-m-d H:i:s
 */
export function format(timestamp, format) {
  var jsdate = timestamp ? new Date(timestamp * 1) : new Date()
  var pad = function(n, c) {
    if ((n = n + '').length < c) {
      return new Array(++c - n.length).join('0') + n
    } else {
      return n
    }
  }
  var txt_weekdays = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
  var txt_ordin = {
    1: 'st',
    2: 'nd',
    3: 'rd',
    21: 'st',
    22: 'nd',
    23: 'rd',
    31: 'st'
  }
  var txt_months = ['', 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  var f = {
    // Day
    d: function() {
      return pad(f.j(), 2)
    },
    D: function() {
      return f.l().substr(0, 3)
    },
    j: function() {
      return jsdate.getDate()
    },
    l: function() {
      return txt_weekdays[f.w()]
    },
    N: function() {
      return f.w() + 1
    },
    S: function() {
      return txt_ordin[f.j()] ? txt_ordin[f.j()] : 'th'
    },
    w: function() {
      return jsdate.getDay()
    },
    z: function() {
      return ((jsdate - new Date(jsdate.getFullYear() + '/1/1')) / 864e5) >> 0
    },

    // Week
    W: function() {
      var a = f.z()
      var b = 364 + f.L() - a
      var nd2
      var nd = (new Date(jsdate.getFullYear() + '/1/1').getDay() || 7) - 1
      if (b <= 2 && (jsdate.getDay() || 7) - 1 <= 2 - b) {
        return 1
      } else {
        if (a <= 2 && nd >= 4 && a >= 6 - nd) {
          nd2 = new Date(jsdate.getFullYear() - 1 + '/12/31')
          return date('W', Math.round(nd2.getTime() / 1000))
        } else {
          return (1 + (nd <= 3 ? (a + nd) / 7 : (a - (7 - nd)) / 7)) >> 0
        }
      }
    },

    // Month
    F: function() {
      return txt_months[f.n()]
    },
    m: function() {
      return pad(f.n(), 2)
    },
    M: function() {
      return f.F().substr(0, 3)
    },
    n: function() {
      return jsdate.getMonth() + 1
    },
    t: function() {
      var n
      if ((n = jsdate.getMonth() + 1) === 2) {
        return 28 + f.L()
      } else {
        if ((n & 1 && n < 8) || (!(n & 1) && n > 7)) {
          return 31
        } else {
          return 30
        }
      }
    },

    // Year
    L: function() {
      var y = f.Y()
      return !(y & 3) && (y % 1e2 || !(y % 4e2)) ? 1 : 0
    },
    // o not supported yet
    Y: function() {
      return jsdate.getFullYear()
    },
    y: function() {
      return (jsdate.getFullYear() + '').slice(2)
    },

    // Time
    a: function() {
      return jsdate.getHours() > 11 ? 'pm' : 'am'
    },
    A: function() {
      return f.a().toUpperCase()
    },
    B: function() {
      // peter paul koch:
      var off = (jsdate.getTimezoneOffset() + 60) * 60
      var theSeconds = jsdate.getHours() * 3600 + jsdate.getMinutes() * 60 + jsdate.getSeconds() + off
      var beat = Math.floor(theSeconds / 86.4)
      if (beat > 1000) beat -= 1000
      if (beat < 0) beat += 1000
      if (String(beat).length === 1) beat = '00' + beat
      if (String(beat).length === 2) beat = '0' + beat
      return beat
    },
    g: function() {
      return jsdate.getHours() % 12 || 12
    },
    G: function() {
      return jsdate.getHours()
    },
    h: function() {
      return pad(f.g(), 2)
    },
    H: function() {
      return pad(jsdate.getHours(), 2)
    },
    i: function() {
      return pad(jsdate.getMinutes(), 2)
    },
    s: function() {
      return pad(jsdate.getSeconds(), 2)
    },
    // u not supported yet

    // Timezone
    // e not supported yet
    // I not supported yet
    O: function() {
      var t = pad(Math.abs((jsdate.getTimezoneOffset() / 60) * 100), 4)
      if (jsdate.getTimezoneOffset() > 0) t = '-' + t
      else t = '+' + t
      return t
    },
    P: function() {
      var O = f.O()
      return O.substr(0, 3) + ':' + O.substr(3, 2)
    },
    // T not supported yet
    // Z not supported yet

    // Full Date/Time
    c: function() {
      return f.Y() + '-' + f.m() + '-' + f.d() + 'T' + f.h() + ':' + f.i() + ':' + f.s() + f.P()
    },
    // r not supported yet
    U: function() {
      return Math.round(jsdate.getTime() / 1000)
    }
  }
  if (!format) {
    return {
      y: f.Y(),
      m: f.m(),
      d: f.d(),
      h: f.h(),
      i: f.i(),
      s: f.s(),
      H: f.H()
    }
  }
  return format.replace(/[\\]?([a-zA-Z])/g, function(t, s) {
    let ret
    if (t !== s) {
      // escaped
      ret = s
    } else if (f[s]) {
      // a date function exists
      ret = f[s]()
    } else {
      // nothing special
      ret = s
    }
    return ret
  })
}
//验证身份证
/*
根据〖中华人民共和国国家标准 GB 11643-1999〗中有关公民身份号码的规定，公民身份号码是特征组合码，由十七位数字本体码和一位数字校验码组成。排列顺序从左至右依次为：六位数字地址码，八位数字出生日期码，三位数字顺序码和一位数字校验码。
    地址码表示编码对象常住户口所在县(市、旗、区)的行政区划代码。
    出生日期码表示编码对象出生的年、月、日，其中年份用四位数字表示，年、月、日之间不用分隔符。
    顺序码表示同一地址码所标识的区域范围内，对同年、月、日出生的人员编定的顺序号。顺序码的奇数分给男性，偶数分给女性。
    校验码是根据前面十七位数字码，按照ISO 7064:1983.MOD 11-2校验码计算出来的检验码。
出生日期计算方法。
    15位的身份证编码首先把出生年扩展为4位，简单的就是增加一个19或18,这样就包含了所有1800-1999年出生的人;
    2000年后出生的肯定都是18位的了没有这个烦恼，至于1800年前出生的,那啥那时应该还没身份证号这个东东，⊙﹏⊙b汗...
下面是正则表达式:
 出生日期1800-2099  (18|19|20)?\d{2}(0[1-9]|1[12])(0[1-9]|[12]\d|3[01])
 身份证正则表达式 /^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i
 15位校验规则 6位地址编码+6位出生日期+3位顺序号
 18位校验规则 6位地址编码+8位出生日期+3位顺序号+1位校验位

 校验位规则     公式:∑(ai×Wi)(mod 11)……………………………………(1)
                公式(1)中：
                i----表示号码字符从由至左包括校验码在内的位置序号；
                ai----表示第i位置上的号码字符值；
                Wi----示第i位置上的加权因子，其数值依据公式Wi=2^(n-1）(mod 11)计算得出。
                i 18 17 16 15 14 13 12 11 10 9 8 7 6 5 4 3 2 1
                Wi 7 9 10 5 8 4 2 1 6 3 7 9 10 5 8 4 2 1
*/
//身份证号合法性验证
//支持15位和18位身份证号
//支持地址编码、出生日期、校验位验证
export function IdentityCodeValid(code) {
  var city = {
    11: '北京',
    12: '天津',
    13: '河北',
    14: '山西',
    15: '内蒙古',
    21: '辽宁',
    22: '吉林',
    23: '黑龙江 ',
    31: '上海',
    32: '江苏',
    33: '浙江',
    34: '安徽',
    35: '福建',
    36: '江西',
    37: '山东',
    41: '河南',
    42: '湖北 ',
    43: '湖南',
    44: '广东',
    45: '广西',
    46: '海南',
    50: '重庆',
    51: '四川',
    52: '贵州',
    53: '云南',
    54: '西藏 ',
    61: '陕西',
    62: '甘肃',
    63: '青海',
    64: '宁夏',
    65: '新疆',
    71: '台湾',
    81: '香港',
    82: '澳门',
    91: '国外 '
  }
  var tip = ''
  var pass = true

  if (!code || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(code)) {
    tip = '身份证号格式错误'
    pass = false
  } else if (!city[code.substr(0, 2)]) {
    tip = '地址编码错误'
    pass = false
  } else {
    //18位身份证需要验证最后一位校验位
    if (code.length == 18) {
      code = code.split('')
      //∑(ai×Wi)(mod 11)
      //加权因子
      var factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2]
      //校验位
      var parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2]
      var sum = 0
      var ai = 0
      var wi = 0
      for (var i = 0; i < 17; i++) {
        ai = code[i]
        wi = factor[i]
        sum += ai * wi
      }
      var last = parity[sum % 11]
      if (parity[sum % 11] != code[17]) {
        tip = '校验位错误'
        pass = false
      }
    }
  }
  return pass
}

/*
 * 获取url中最后一个/ 后的名称
 */
export function getLastPathName(url) {
  //获取最后一个/的位置
  let site = url.lastIndexOf('/')
  //截取最后一个/后的值
  return url.substring(site + 1, url.length)
}

//比较两个时间
export function diffTime(endTime) {
  return new Date(endTime.replace(/\-/g, '/')) - new Date() > 0
}

//下载文件
export function download(url, filename) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest()
    xhr.open('GET', url, true)
    xhr.setRequestHeader('Authorization', store.state.token)
    xhr.responseType = 'blob'
    xhr.onload = () => {
      if (xhr.status === 200) {
        if (window.navigator.msSaveOrOpenBlob) {
          navigator.msSaveBlob(xhr.response, filename)
        } else {
          const link = document.createElement('a')
          const body = document.querySelector('body')

          link.href = window.URL.createObjectURL(xhr.response)
          link.download = filename

          // fix Firefox
          link.style.display = 'none'
          body.appendChild(link)

          link.click()
          body.removeChild(link)

          window.URL.revokeObjectURL(link.href)
        }
        resolve(200)
      }
    }
    xhr.error = () => {
      reject()
    }

    xhr.send()
  })
}

//1,2,3 转 一，二，三
export function numberChinese(number) {
  var units = '个十百千万@#%亿^&~',
    chars = '零一二三四五六七八九'
  var a = (number + '').split(''),
    s = []
  if (a.length > 12) {
    throw new Error('too big')
  } else {
    for (var i = 0, j = a.length - 1; i <= j; i++) {
      if (j == 1 || j == 5 || j == 9) {
        //两位数 处理特殊的 1*
        if (i == 0) {
          if (a[i] != '1') s.push(chars.charAt(a[i]))
        } else {
          s.push(chars.charAt(a[i]))
        }
      } else {
        s.push(chars.charAt(a[i]))
      }
      if (i != j) {
        s.push(units.charAt(j - i))
      }
    }
  }
  //return s;
  return s
    .join('')
    .replace(/零([十百千万亿@#%^&~])/g, function(m, d, b) {
      //优先处理 零百 零千 等
      b = units.indexOf(d)
      if (b != -1) {
        if (d == '亿') return d
        if (d == '万') return d
        if (a[j - b] == '0') return '零'
      }
      return ''
    })
    .replace(/零+/g, '零')
    .replace(/零([万亿])/g, function(m, b) {
      // 零百 零千处理后 可能出现 零零相连的 再处理结尾为零的
      return b
    })
    .replace(/亿[万千百]/g, '亿')
    .replace(/[零]$/, '')
    .replace(/[@#%^&~]/g, function(m) {
      return { '@': '十', '#': '百', '%': '千', '^': '十', '&': '百', '~': '千' }[m]
    })
    .replace(/([亿万])([一-九])/g, function(m, d, b, c) {
      c = units.indexOf(d)
      if (c != -1) {
        if (a[j - c] == '0') return d + '零' + b
      }
      return m
    })
}

//复制文本
export function copy(text) {
  let oInput = document.createElement('input')
  oInput.value = text
  document.body.appendChild(oInput)
  oInput.select()
  document.execCommand('Copy')
  ElMessage({
    message: '复制成功',
    type: 'success',
    duration: 5 * 1000
  })
  oInput.remove()
}

//默认corpId
export const defaultCorpId = '123456789'

//秒转时分秒
export function timeTostr(seconds) {
  let [day, hour, minute, second] = [0, 0, 0, 0]
  day = Math.floor(seconds / (60 * 60 * 24))
  // hour为给后面计算秒和分等用的(基于显示天的前提下计算)
  hour = Math.floor(seconds / (60 * 60)) - day * 24
  // showHour为需要显示的小时
  let showHour = null

  // 如果不显示天数，将“天”部分的时间折算到小时中去
  showHour = Math.floor(seconds / (60 * 60))
  minute = Math.floor(seconds / 60) - hour * 60 - day * 24 * 60
  second = Math.floor(seconds) - day * 24 * 60 * 60 - hour * 60 * 60 - minute * 60
  // 如果小于10，在前面补上一个"0"
  showHour = showHour < 10 ? '0' + showHour : showHour
  minute = minute < 10 ? '0' + minute : minute
  second = second < 10 ? '0' + second : second
  day = day < 10 ? '0' + day : day
  return {
    h: showHour,
    i: minute,
    s: second
  }
}

<template>
  <div class="my-main-only-os">
    <slot />
  </div>
</template>

<script>
</script>

<style lang="scss"></style>

<template>
  <div class="class-popup-box">
    <el-dialog v-model="dialogVisible" width="900px" :title="title" :close-on-click-modal="false" :close-on-press-escape="false" :show-close="showClose">
      <div class="popup-box">
        <!-- <div class="title-box">
          <b>{{title}}</b>
        </div> -->
        <div class="mian-box">
          <div class="left-box">
            <template v-for="(v,i) in list" :key="i">
              <div class="li cursor-pointer" @click="setActive(0,{id:v.id,index:i})" :class="{active:i == activeArr[0].index}" v-if="!!v.children.length">
                {{v.name}}
              </div>
            </template>
          </div>
          <div class="right-box">
            <div class="li" v-for="(v,i) in tree2" @click="setActive(1,{id:v.id,index:i,name:v.name})" :class="{active:i == activeArr[1].index}">
              <el-button round>
                <div class="cl1">{{v.name}}</div>
              </el-button>
            </div>
          </div>
        </div>
      </div>
      <!-- <template #footer>
        <div class="dialog-footer">
          <el-button round @click="change">
            选好了
          </el-button>
        </div>
      </template> -->
    </el-dialog>
  </div>
</template>

<script>
import { memberSelectedUpdate } from '@/api/index.js'
import { poll, debounce } from '@/utils/index.js'
import { classPath } from '@/utils/business.js'
import { setLocalStrorage } from '@/utils/storage.js'
import { mapState } from 'vuex'
export default {
  props: {
    showClose: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: '请选择您的考试意向'
    }
  },
  data() {
    return {
      id: '',
      dialogVisible: false,
      activeArr: [
        {
          id: '',
          index: 0
        },
        {
          id: '',
          index: 0
        }
      ]
    }
  },
  created() {
    //自动轮询查询list数据是否被加载
    poll(
      res => {
        if (this.id) {
          //如果传入id就解析
          classPath(this.list, this.id).forEach((item, index) => {
            this.activeArr[index] = item
            this.setActive(index, {
              id: item.id,
              index: item.index
            })
          })
        } else {
          this.setActive(0, {
            id: this.list[0].id,
            index: 0
          })
        }
      },
      () => {
        return this.list.length
      }
    )
  },
  computed: {
    ...mapState({
      list: state => state.classList
    }),
    tree2() {
      return this.list[this.activeArr[0].index] ? this.list[this.activeArr[0].index].children : []
    },
    tree3() {
      return this.tree2[this.activeArr[1].index] ? this.tree2[this.activeArr[1].index].children : []
    }
  },
  methods: {
    open(id) {
      //暴露给外部使用
      if (id) {
        this.id = id
      }
      this.dialogVisible = true
    },
    close() {
      //暴露给外部使用
      this.dialogVisible = false
    },
    setActive(b, val) {
      this.activeArr[b] = val
      this.activeArr.forEach((item, i) => {
        if (b < i) {
          if (i == 1) {
            this.activeArr[i] = {
              id: this.tree2[0].id,
              name: this.tree2[0].name,
              index: 0,
              selected: 1 //没用只是为了匹配后台参数
            }
          }
        }
      })
      if (val.name) {
        this.change()
      }
    },
    change() {
      this.$emit('change', this.activeArr)
      //全局储存
      setLocalStrorage('activeClass', this.activeArr[this.activeArr.length - 1])
      memberSelectedUpdate({ list: [this.activeArr[this.activeArr.length - 1]] })
    }
  }
}
</script>

<style scoped lang="scss">
.class-popup-box {
  :deep .el-overlay {
    .el-dialog__header {
      padding-bottom: 20px;
    }
    .el-dialog__title {
      font-size: 16px;
      color: #333333;
      font-weight: 500;
    }
    .el-dialog__body {
      margin-top: -20px;
      padding: 0px 0 21px 0;
    }
    .el-dialog__footer {
      padding-bottom: 11px;
      padding-top: 15px;
      border-top: 1px solid #e6e6e6;
    }
  }
  .dialog-footer {
    text-align: center;
    button {
      width: 320px;
    }
  }
  .popup-box {
    .title-box {
      font-size: 16px;
      margin-left: 18px;
      b {
        color: #333;
      }
      .active {
        color: #006df0;
      }
      .txing {
        color: #999;
      }
    }
    .mian-box {
      margin-top: 20px;
      display: flex;
      .left-box {
        width: 187px;
        overflow-y: scroll;
        height: 450px;
        flex: 0 0 auto;
        .li {
          font-size: 16px;
          color: #333333;
          line-height: 40px;
          text-align: center;
          position: relative;
          &.active {
            color: #006df0;
            background-color: #f1f3f7;
            &::after {
              content: '';
              position: absolute;
              height: 100%;
              width: 2px;
              left: 0;
              background-color: #006df0;
            }
          }
        }
      }
      .right-box {
        overflow-y: scroll;
        height: 450px;
        margin-left: 6px;
        .li {
          display: inline-block;
          margin-left: 24px;
          margin-bottom: 21px;
          &.active {
            button {
              background-color: #006df0;
              border: none;
              color: #ffffff;
            }
          }
          button {
            width: 140px;
            padding: 0;
          }
        }
      }
    }
  }
}
</style>

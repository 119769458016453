import { returnFloat } from '@/utils/number.js'
import { format, desens, timeTostr } from '@/utils/index.js'
//补零
export function addFloat(value) {
    if (!value) return '0.00';
    return returnFloat(value);
}

//去除.0 和 .00
export function formatPrecent(value) {
    let str = value + ''
    if (str.endsWith('.00')) {
        str = str.replace(".00", '');
    } else if (str.endsWith('0')) {
        str = str.slice(0, -1);
    }
    return str
}

//时间戳转时间
export function returnformat(value, type) {
    if (!value) return '';
    return format(value, type);
}
//手机号脱敏
export function returndesens(value) {
    if (!value) return '';
    return desens(value);
}

//秒转HH:mm:ss
export function ftimeTostr(val) {
  let timeObj = timeTostr(val)
  return `${timeObj.h}:${timeObj.i}:${timeObj.s}`
}

import _ from 'lodash'
//学院项目科目分类解析

export function classPath(classList, id) {
  let arr = []
  setPath(classList, id)
  function setPath(classList, id) {
    for (let i in classList) {
      let item = classList[i]
      if (item.children && item.children.length) {
        if (setPath(item.children, id)) {
          arr.push({
            id: item.id,
            index: i,
            item: item
          })
          return true
        }
      }
      if (item.id === id) {
        arr.push({
          id: item.id,
          index: i,
          item: item
        })
        return true
      }
    }
  }
  return arr.reverse()
}

//单选、多选、判断、答案是否正确
export function isQuestionAnswerRight(question) {
  //单选、判断
  if (question.questionType == 0 || question.questionType == 2) {
    return question.answer[0] == question.answerContent
  } else if (question.questionType == 1 || question.questionType == 6) {
    //多选,不定项
    return !_.difference(question.answer, question.answerContent).length && question.answer.length === question.answerContent.length
  }
}

//查看答案/保存试卷/交卷 question 参数组装
export function getQuestionItemParams(item) {
  let paramsItem = {}
  if (item.questionType == 0 || item.questionType == 2) {
    if ((item.answerContent && item.answerContent.length) || item.isShowAnswer) {
      paramsItem = {
        answerQuestionRecordId: item.id,
        answerContent: JSON.stringify([item.answerContent])
      }
    }
  } else if (item.questionType == 1 || item.questionType == 3 || item.questionType == 4 || item.questionType == 6) {
    //多选，填空，简答
    if ((item.answerContent && item.answerContent.some(item => item.length)) || item.isShowAnswer) {
      paramsItem = {
        answerQuestionRecordId: item.id,
        answerContent: JSON.stringify(item.answerContent)
      }
    }
  } else if (item.questionType == 5) {
    //案例分析题
    let saveChildrenPaperRecordDTOList = []
    if ((item.answerContent && item.answerContent.some(item => item.length)) || item.isShowAnswer) {
      item.children.forEach((childItem, index) => {
        saveChildrenPaperRecordDTOList.push({
          answerContent: JSON.stringify([item.answerContent[index]]),
          answerQuestionRecordId: childItem.id
        })
      })
      if (saveChildrenPaperRecordDTOList.length) {
        paramsItem = {
          answerQuestionRecordId: item.id,
          saveChildrenPaperRecordDTOList: saveChildrenPaperRecordDTOList
        }
      }
    }
  } else if (item.questionType == 7) {
    //综合题
    let saveChildrenPaperRecordDTOList = []
    if (item.children && item.children.length) {
      item.children.forEach((sub, sIndex) => {
        if (Object.prototype.toString.call(sub.answerContent) === '[object String]') {
          saveChildrenPaperRecordDTOList.push({
            answerContent: JSON.stringify([sub.answerContent]),
            answerQuestionRecordId: sub.id
          })
        } else if (Object.prototype.toString.call(sub.answerContent) === '[object Array]') {
          saveChildrenPaperRecordDTOList.push({
            answerContent: JSON.stringify(sub.answerContent),
            answerQuestionRecordId: sub.id
          })
        }
      })

      if (saveChildrenPaperRecordDTOList.length) {
        paramsItem = {
          answerQuestionRecordId: item.id,
          saveChildrenPaperRecordDTOList: saveChildrenPaperRecordDTOList
        }
      }
    }
  }
  return paramsItem
}

//拿到请求试卷数据，组装数据展示
export function showQuestionItem(question, startIndex, isFindIndex = false) {
  let findStartObj = {}
  if (question.questionType == 0 || question.questionType == 1 || question.questionType == 2 || question.questionType == 6) {
    question.answer = question.answer.map(item => (item = item + ''))
    question.options.forEach(item => {
      item.id = item.id + ''
    })
  }
  if (question.questionType == 0 || question.questionType == 2) {
    if (question.answerContent && question.answerContent.length) {
      question.answerContent = question.answerContent[0]
      const findIndex = question.options.findIndex(item => item.id + '' === question.answerContent[0])
      if (findIndex != -1) {
        question.options[findIndex].isChecked = true
      }
      if (question.answerResult == 2) {
        question.isDone = true
      } else {
        question.isDisabled = true
        question.isShowAnswer = true
        question.isAnswered = true
        question.isAnswerRight = question.answerResult == 1
      }
    } else {
      question.answerContent = ''
      if (startIndex == -1 && isFindIndex) {
        findStartObj = {
          id: question.id
        }
      }
    }
  } else if (question.questionType == 1 || question.questionType == 6) {
    if (question.answerContent && question.answerContent.some(item => item.length)) {
      for (let item of question.options) {
        for (let answerItem of question.answerContent) {
          if (answerItem == item.id) {
            item.isChecked = true
            break
          } else {
            item.isChecked = false
          }
        }
      }
      if (question.answerResult == 2) {
        question.isDone = true
      } else {
        question.isDisabled = true
        question.isShowAnswer = true
        question.isAnswered = true
        question.isAnswerRight = question.answerResult == 1
      }
    } else {
      question.answerContent = []
      if (startIndex == -1 && isFindIndex) {
        findStartObj = {
          id: question.id
        }
      }
    }
  } else if (question.questionType == 3) {
    if (question.answerContent && question.answerContent.some(item => item.length)) {
      if (question.answerResult == 2) {
        question.isDone = true
      } else {
        question.isDisabled = true
        question.isShowAnswer = true
        question.isAnswered = true
      }
    } else {
      question.answerContent = new Array(question.answer.length).fill('')
      if (startIndex == -1 && isFindIndex) {
        findStartObj = {
          id: question.id
        }
      }
    }
  } else if (question.questionType == 4) {
    if (question.answerContent && question.answerContent.some(item => item.length)) {
      question.isDone = true
      if (question.answerResult == 3) {
        question.isDisabled = true
        question.isAnswered = true
        question.isShowAnswer = true
      }
    } else {
      question.answerContent = ['']
      if (startIndex == -1 && isFindIndex) {
        findStartObj = {
          id: question.id
        }
      }
    }
  } else if (question.questionType == 5) {
    let obj = question.children.some(item => item.answerContent)
    if (question.answerResult == 3) {
      question.isDisabled = true
      question.isAnswered = true
      question.isShowAnswer = true
    }
    if (obj) {
      question.isDone = true
      let answers = []
      question.children.forEach(objItem => {
        answers.push(objItem.answerContent[0])
      })
      question.answerContent = answers
    } else {
      question.answerContent = new Array(question.children.length).fill('')
      if (startIndex == -1 && isFindIndex) {
        findStartObj = {
          id: question.id
        }
      }
    }
  }
  return findStartObj
}

//找到题目在列表中的位置信息
export function findQuestionLoaction(listData, id) {
  let positionInfo = {}
  for (let i = 0; i < listData.length; i++) {
    const index = listData[i].questionList.findIndex(question => question.id == id)
    if (index != -1) {
      positionInfo = {
        partIndex: i,
        index: index
      }
      break
    }
  }
  return positionInfo
}

//题库 试题类型
export const examQuestionTypes = [
  {
    id: '0',
    name: '单选题',
    type: 'single'
  },
  {
    id: '1',
    name: '多选题',
    type: 'multi'
  },
  {
    id: '2',
    name: '判断题',
    type: 'trueorfalse'
  },
  {
    id: '3',
    name: '填空题',
    type: 'blank'
  },
  {
    id: '4',
    name: '简答题',
    type: 'short'
  },
  {
    id: '5',
    name: '案例分析题',
    type: 'case'
  },
  {
    id: '6',
    name: '不定项选择',
    type: 'nosure'
  },
  {
    id: '7',
    name: '综合题',
    type: 'combined'
  }
]

// 试卷类型
export const examPaperTypes = [
  {
    id: '0',
    iconName: 'zjlx',
    name: '章节练习'
  },
  {
    id: '3',
    iconName: 'mryl',
    name: '每日一练'
  },
  {
    id: '1',
    iconName: 'lnzt',
    name: '历年真题'
  },
  {
    id: '2',
    iconName: 'mnsj',
    name: '模拟试卷'
  },
  {
    id: '4',
    iconName: 'yct',
    name: '易错题'
  },
  {
    id: '6',
    iconName: 'zcs',
    name: '周测试'
  },
  {
    id: '5',
    iconName: 'ycs',
    name: '月测试'
  },
  {
    id: '9',
    iconName: 'mkds',
    name: '通关测试'
  },
  {
    id: '10',
    iconName: 'mkds',
    name: '课后训练'
  },
  {
    id: '7',
    iconName: 'khgf',
    name: '考后估分'
  }
]

// 考频
export const examFrequencyArr = [
  {
    id: '0',
    name: '低'
  },
  {
    id: '1',
    name: '中'
  },
  {
    id: '2',
    name: '高'
  }
]

// 重要程度
export const importLevelArr = [
  {
    id: '0',
    name: '一般'
  },
  {
    id: '1',
    name: '重要'
  },
  {
    id: '2',
    name: '非常重要'
  }
]
//试题难度
export const difficultyLevelArr = [
  {
    id: '0',
    name: '简单'
  },
  {
    id: '1',
    name: '一般'
  },
  {
    id: '2',
    name: '困难'
  }
]

//考试题目动画切换偏移量
export const leftAnimateVal = 808

//客观题(单选、多选、判断、不定项选择)
export const objectQuestions = ['0', '1', '2', '6']
//主观题 （填空题、简答题、案例分析、综合题）
export const subjectQuestions = ['3', '4', '5', '7']

//只有练习模式的试卷
export const practiceModes = ['0', '3', '4']

//练习模式考试模式都有的试卷
export const practiceAndExamModes = ['1', '2', '5', '6', '7', '9', '10']

<template>
  <div class="login-view">
    <div class="top-box">
      <div class="header-box">
        <div class="w1200">
          <div class="left-box">
            <template v-if="isDefaultCorpId">
              <img class="cursor-pointer" style="width:142px;height:38px" @click="$router.push('/')" src="@/assets/img/login/login-logo.png" />
            </template>
            <template v-else>
              <img
                :src="sassConfig.logo ? sassConfig.logo : 'https://zj-web-source.oss-cn-beijing.aliyuncs.com/app/logo-icon.png'"
                style="width:36;height:36px"
                alt=""
              />
              <span>{{ sassConfig.name }}</span>
            </template>
          </div>
        </div>
      </div>
      <div class="w1200 main-big-box">
        <img src="@/assets/img/login/login-bg.png" class="left-box" />
        <template v-if="isShowSassList">
          <div class="login-change-sass" style="position: relative;">
            <change-sass></change-sass>
          </div>
        </template>
        <template v-else>
          <div class="right-box">
            <el-card class="box-card p-l-30 p-r-30" style="position: relative;">
              <!--登陆-->
              <template v-if="formType == 'login'">
                <h3>
                  <b>登录{{ sassConfig.name }}</b>
                </h3>
                <el-tabs
                  class="login-tabs"
                  :class="isChangeSmall ? 'change-tabs' : ''"
                  v-model="tabsName"
                  :stretch="true"
                  style="width:80%;margin:0 10%;"
                  @tab-click="tabClick"
                >
                  <el-tab-pane label="密码登录" name="password"></el-tab-pane>
                  <el-tab-pane label="验证码登录" name="smscode"></el-tab-pane>
                </el-tabs>
                <div class="row m-t-15">
                  <div class="col-sm-12" v-if="tabsName == 'password'">
                    <el-input class="login-input" placeholder="输入手机号" v-model="username" :prefix-icon="$icon.Cellphone"> </el-input>
                    <el-input
                      class="login-input m-t-20"
                      :type="passwordVisible"
                      placeholder="输入密码"
                      v-model="password"
                      :prefix-icon="$icon.Lock"
                      @keyup.enter.native="loginIn"
                    >
                      <template #suffix>
                        <i
                          class="iconfont"
                          style="margin-right:10px"
                          :class="passwordVisible == 'text' ? 'icon-xianshi1' : 'icon-yincang'"
                          @click="showPass('common')"
                        ></i>
                      </template>
                    </el-input>
                  </div>
                  <div class="col-sm-12" v-if="tabsName == 'smscode'">
                    <el-input class="login-input" placeholder="输入手机号" :prefix-icon="$icon.Cellphone" v-model="username"> </el-input>
                    <el-input
                      class="login-input m-t-20"
                      placeholder="输入验证码"
                      :prefix-icon="$icon.ChatLineSquare"
                      v-model="smscode"
                      @keyup.enter.native="loginCode"
                    >
                      <template #suffix>
                        <getSmsCode style="color: #006DF0;padding-right: 13px;" :formData="{ phone: username, type: 0 }"></getSmsCode>
                      </template>
                    </el-input>
                  </div>
                </div>
                <div class="m-t-10" style="display: flex;justify-content: space-between;">
                  <div class="text-primary cursor-pointer" @click="formType = 'register'">
                    立即注册
                  </div>
                  <div class="cursor-pointer" @click="formType = 'returnPass'">
                    忘记密码
                  </div>
                </div>
                <h6 class="text-center m-t-35 hint" v-if="isDefaultCorpId">
                  登录即代表阅读并同意<span class="text-primary">
                    <router-link :to="`/login/protocol?type=1&corpId=${corpId}`" target="_blank">《中建教育服务协议》</router-link>
                  </span>
                </h6>
                <div class="row m-t-20 m-b-30">
                  <div class="col-sm-12">
                    <el-button type="primary" round style="width:100%;font-size:16px" @click="loginIn" v-if="tabsName == 'password'">登录</el-button>
                    <el-button type="primary" round style="width:100%;font-size:16px" @click="loginCode" v-if="tabsName == 'smscode'">登录</el-button>
                  </div>
                </div>
                <template v-if="isDefaultCorpId">
                  <el-divider>微信账号登录</el-divider>
                  <div class="row m-t-20">
                    <div class="col-sm-12 text-center">
                      <img src="@/assets/img/wechat_logo_icon.png" class="wechat-logo" @click="wechatLogin" />
                    </div>
                  </div>
                </template>
              </template>
              <!--忘记密码-->
              <template v-else-if="formType == 'returnPass'">
                <h3><b>重置密码</b></h3>
                <div class="row m-t-20">
                  <div class="col-sm-12">
                    <el-input class="login-input" placeholder="输入手机号" :prefix-icon="$icon.Cellphone" v-model="username"> </el-input>
                    <el-input class="login-input m-t-20" placeholder="输入验证码" :prefix-icon="$icon.ChatLineSquare" v-model="smscode">
                      <template #suffix>
                        <getSmsCode style="color: #006DF0;padding-right: 13px;" :formData="{ phone: username, type: 2 }"></getSmsCode>
                      </template>
                    </el-input>
                    <el-input
                      class="login-input m-t-20"
                      :type="passwordVisible"
                      autocomplete="new-password"
                      placeholder="6-18位字母、数字、符号任两种"
                      :prefix-icon="$icon.Lock"
                      v-model="password"
                    >
                      <template #suffix>
                        <i
                          class="iconfont"
                          style="margin-right:10px"
                          :class="passwordVisible == 'text' ? 'icon-xianshi1' : 'icon-yincang'"
                          @click="showPass('common')"
                        ></i>
                      </template>
                    </el-input>
                    <el-input
                      class="login-input m-t-20"
                      :type="findPwdVisible"
                      autocomplete="new-password"
                      placeholder="请输入确认密码"
                      :prefix-icon="$icon.Lock"
                      v-model="yPassword"
                    >
                      <template #suffix>
                        <i
                          class="iconfont"
                          style="margin-right:10px"
                          :class="findPwdVisible == 'text' ? 'icon-xianshi1' : 'icon-yincang'"
                          @click="showPass('find')"
                        ></i>
                      </template>
                    </el-input>
                  </div>
                </div>
                <div class="row m-t-20">
                  <div class="col-sm-12">
                    <el-button type="primary" round style="width:100%;font-size:16px" @click="editIn">重置密码</el-button>
                  </div>
                </div>
                <h5 class="text-center m-t-30">
                  <span class="text-primary cursor-pointer" style="font-size:14px" @click="formType = 'login'">返回登录</span>
                </h5>
              </template>
              <!--注册-->
              <template v-else-if="formType == 'register'">
                <h3><b>注册中建教育</b></h3>
                <div class="row m-t-20">
                  <div class="col-sm-12 m-t-35">
                    <el-input class="login-input" placeholder="输入手机号" :prefix-icon="$icon.Cellphone" v-model="username"> </el-input>
                    <el-input class="login-input m-t-20" placeholder="输入验证码" :prefix-icon="$icon.ChatLineSquare" v-model="smscode">
                      <template #suffix>
                        <getSmsCode style="color: #006DF0;padding-right: 13px;" :formData="{ phone: username, type: 1 }"></getSmsCode>
                      </template>
                    </el-input>
                    <el-input
                      class="login-input m-t-20"
                      :type="passwordVisible"
                      autocomplete="new-password"
                      placeholder="6-18位字母、数字、符号"
                      :prefix-icon="$icon.Lock"
                      v-model="password"
                    >
                      <template #suffix>
                        <i
                          class="iconfont"
                          style="margin-right:10px"
                          :class="passwordVisible == 'text' ? 'icon-xianshi1' : 'icon-yincang'"
                          @click="showPass('common')"
                        ></i>
                      </template>
                    </el-input>
                  </div>
                </div>
                <div class="row m-t-20">
                  <div class="col-sm-12">
                    <el-button type="primary" round style="width:100%;font-size:16px" @click="signUp">注册</el-button>
                  </div>
                </div>
                <div class="row m-t-10" v-if="isDefaultCorpId">
                  <div class="col-sm-12 text-left">
                    <el-checkbox v-model="agreement">
                      <span class="text-muted">我已阅读并同意</span>
                      <span class="text-primary">
                        <router-link :to="`/login/protocol?type=1&corpId=${corpId}`" target="_blank">《中建教育服务协议》</router-link>
                      </span>
                    </el-checkbox>
                  </div>
                </div>
                <h5 class="text-center m-t-40" style="font-size:14px">
                  已有账号？ <span class="text-primary cursor-pointer" @click="formType = 'login'">去登录</span>
                </h5>
              </template>
              <!--扫码登陆-->
              <template v-else-if="formType == 'qrscan' && isDefaultCorpId">
                <h3><b>扫码登录</b></h3>
                <div class="row">
                  <div class="qr-view" ref="qrCodeDiv"></div>
                </div>
                <h5 class="text-center m-t-50 m-b-50" style="font-size:14px">打开 <span class="text-primary">中建教育App</span> 扫一扫登录</h5>
              </template>
              <!-- 微信登录 -->
              <template v-else-if="formType == 'wechat' && isDefaultCorpId">
                <div class="wechat-login-box">
                  <h3><img src="@/assets/img/wechat_logo_icon.png" alt="" /><b>微信登录</b></h3>

                  <template v-if="isWechatBindMobile">
                    <div class="col-sm-12 m-t-35">
                      <p class="bind-des">首次使用第三方登陆，需要绑定手机号码哦～</p>
                      <el-input class="login-input" placeholder="输入手机号" :prefix-icon="$icon.Cellphone" v-model="bindMobile"> </el-input>
                      <el-input class="login-input m-t-20" placeholder="输入验证码" :prefix-icon="$icon.ChatLineSquare" v-model="smscode">
                        <template #suffix>
                          <getSmsCode style="color: #006DF0;padding-right: 13px;" :formData="{ phone: bindMobile, type: 3 }"></getSmsCode>
                        </template>
                      </el-input>
                      <el-button type="primary" round style="width:100%;margin-top:34px" @click="bindWechatClick">绑定</el-button>
                    </div>
                  </template>
                  <template v-else>
                    <div class="qr-view" id="wechat-qr"></div>
                    <p class="hint"><b>微信扫码</b>一键登录/注册</p>
                    <p class="des">未注册的微信号将自动注册</p>
                  </template>

                  <div class="agree">
                    扫码即表示同意 <router-link :to="`/login/protocol?type=1&corpId=${corpId}`" target="_blank">《中建教育服务协议》</router-link>
                  </div>
                  <div class="change-box">
                    <span @click="changeCodeClick">验证码登录</span>
                    <span @click="formType = 'login'">密码登录</span>
                  </div>
                </div>
              </template>
              <template v-if="isDefaultCorpId">
                <el-tooltip
                  v-if="formType != 'qrscan'"
                  effect="dark"
                  :content="formType == 'wechat' ? 'App登录在这里' : '扫码登录在这里'"
                  placement="left"
                >
                  <img class="qrcode-half" src="@/assets/img/qrcode-half.png" @click="getScanToken" />
                </el-tooltip>
                <el-tooltip v-else effect="light" content="密码登录在这里" placement="left">
                  <img class="qrcode-half" src="@/assets/img/logincode-half.png" @click="formType = 'login'" />
                </el-tooltip>
              </template>
            </el-card>
          </div>
        </template>
      </div>
    </div>
    <div class="login-footer-box">
      <footerDiy></footerDiy>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import {
  loginPwd,
  setRegister,
  retrievePwd,
  loginCode,
  getScanToken,
  getLoginqrCode,
  memberSelected,
  thirdRelation,
  getUserInfoByWeChatCode,
  findSass,
  changeSass,
  getDefaultSass
} from '@/api/index.js'
import footerDiy from '@/components/login-footer.vue'
import getSmsCode from '@/components/getSmsCode.vue'
import { ElMessage } from 'element-plus'
import { setLocalStrorage, getLocalStrorage } from '@/utils/storage.js'
import { getRequestParameters } from '@/utils/index'
import QRCode from 'qrcodejs2'
import ChangeSass from '@/components/change-sass/change-sass.vue'
import { defaultCorpId } from '@/utils/index.js'
export default {
  data() {
    return {
      formType: 'login',
      tabsName: 'password',
      username: '', //手机号
      password: '', //密码
      yPassword: '', //确认密码
      smscode: '', //验证码
      agreement: true, //是否勾选用户协议
      scanData: {},
      passwordVisible: 'password',
      findPwdVisible: 'password',
      isChangeSmall: false,
      wechatQrStyle:
        'data:text/css;base64,ICAgICAgLmltcG93ZXJCb3ggLnRpdGxlIHsKICAgICAgICBkaXNwbGF5OiBub25lOwogICAgICB9CiAgICAgIC5pbXBvd2VyQm94IC5xcmNvZGUgewogICAgICAgIG1hcmdpbjogMDsKICAgICAgICB3aWR0aDogMTYycHg7CiAgICAgICAgaGVpZ2h0OiAxNjJweDsKICAgICAgfQogICAgICAuaW1wb3dlckJveCAuc3RhdHVzIHsKICAgICAgICBkaXNwbGF5OiBub25lOwogICAgICB9CiAgICAgIC5pbXBvd2VyQm94IC5pbmZvIHsKICAgICAgICBkaXNwbGF5OiBub25lOwogICAgICB9',

      isWechatBindMobile: false,
      //微信扫码绑定手机号
      bindMobile: '',
      bindId: '',
      isShowSassList: false
    }
  },
  components: {
    footerDiy,
    getSmsCode,
    ChangeSass
  },
  watch: {
    formType(newVal, oldVal) {
      this.passwordVisible = 'password'
      this.findPwdVisible = 'password'
      this.password = ''
    }
  },
  computed: {
    ...mapState(['corpId', 'sassConfig', 'sassList']),
    isDefaultCorpId() {
      return this.corpId == defaultCorpId && !this.$store.getters.isSass
    }
  },
  methods: {
    changeCodeClick() {
      this.formType = 'login'
      this.tabsName = 'smscode'
    },
    wechatLogin() {
      this.formType = 'wechat'
      this.$nextTick(() => {
        let dvcId = getLocalStrorage('UUID') || setLocalStrorage('UUID', uuid())
        let obj = new WxLogin({
          self_redirect: false, //值为true时，直接在二维码的弹窗中进行页面跳转及其余操作,
          id: 'wechat-qr', //需要承载二维码的容器id
          appid: 'wx80dbda210dcc7f39',
          scope: 'snsapi_login', //网页授权，目前网页只有这一种方式，静默授权
          redirect_uri: encodeURIComponent(`${window.location.origin}${window.location.pathname}`),
          state: `${this.corpId},${dvcId}`,
          style: 'black',
          href: this.wechatQrStyle
        })
      })
    },
    //微信扫码登录绑定手机号
    bindWechatClick() {
      const parameter = {
        phone: this.bindMobile,
        id: this.bindId,
        code: this.smscode,
        type: 0
      }
      thirdRelation(parameter).then(res => {
        this.inSty(res)
      })
    },
    tabClick(e) {
      const width = document.documentElement.clientWidth
      if (width < 1300) {
        this.isChangeSmall = e.index == 1
      }
    },
    showPass(type) {
      if (type == 'common') {
        if (this.passwordVisible === 'text') {
          this.passwordVisible = 'password'
        } else {
          this.passwordVisible = 'text'
        }
      } else if ('find') {
        if (this.findPwdVisible === 'text') {
          this.findPwdVisible = 'password'
        } else {
          this.findPwdVisible = 'text'
        }
      }
    },
    editIn() {
      if (this.password.length < 6 || this.password.length > 18) {
        ElMessage({
          message: '请输入6-18位字母、数字、符号',
          type: 'warning',
          duration: 3 * 1000
        })
        return
      }
      if (this.password != this.yPassword) {
        ElMessage({
          message: '密码不一致',
          type: 'warning',
          duration: 3 * 1000
        })
        return
      }
      const parameter = {
        code: this.smscode,
        phone: this.username,
        pwd: this.password
      }
      retrievePwd(parameter).then(res => {
        this.loginIn()
      })
    },
    signUp() {
      //注册
      if (!this.agreement) {
        ElMessage({
          message: '请阅读并同意，中建教育服务协议',
          type: 'warning',
          duration: 3 * 1000
        })
        return
      }
      if (this.password.length < 6 || this.password.length > 18) {
        ElMessage({
          message: '密码格式不正确',
          type: 'warning',
          duration: 3 * 1000
        })
        return
      }
      const parameter = {
        code: this.smscode,
        phone: this.username,
        pwd: this.password
      }
      setRegister(parameter).then(res => {
        this.loginIn()
      })
    },
    loginCode() {
      //验证码登录
      const parameter = {
        phone: this.username,
        code: this.smscode
      }
      loginCode(parameter).then(res => {
        this.inSty(res)
      })
    },
    loginIn() {
      //登录
      const parameter = {
        phone: this.username,
        pwd: this.password
      }
      loginPwd(parameter).then(res => {
        this.inSty(res)
      })
    },
    //获取二维码
    getScanToken() {
      this.formType = 'qrscan'
      getScanToken().then(res => {
        this.scanData = res.data
        const qrCode = new QRCode(this.$refs.qrCodeDiv, {
          text: JSON.stringify(res.data),
          width: 180,
          height: 180,
          colorDark: '#333333', //二维码颜色
          colorLight: '#ffffff', //二维码背景色
          correctLevel: QRCode.CorrectLevel.L //容错率，L/M/H
        })
        qrCode._el.title = ''
        this.getLoginqrCode()
      })
    },
    //持续获取扫码登录状态
    getLoginqrCode() {
      getLoginqrCode({
        uuid: this.scanData.content
      }).then(res => {
        setTimeout(() => {
          if (res.data) {
            this.inSty(res)
            return
          }
          if (this.formType == 'qrscan') {
            this.getLoginqrCode()
          }
        }, 1000)
      })
    },
    //进入系统
    async inSty(res = {}) {
      this.$store.commit('SER_TOKEN', res.data)
      this.getMoreUserInfo()
      //   //获取默认sass
      //   const { data: defaultSassData } = await getDefaultSass({
      //     Authorization: res.data.token
      //   })

      //   if (defaultSassData.loginInfo) {
      //     this.$store.commit('SER_TOKEN', defaultSassData.loginInfo)
      //   }
      //   const { data: findSassData } = await findSass({})
      //   this.$store.commit('SET_SASSLIST', findSassData)
      //   if (defaultSassData.existDefault) {
      //     this.getMoreUserInfo()
      //   } else {
      //     //保存sass列表
      //     this.isShowSassList = true
      //   }
    },
    getMoreUserInfo() {
      this.$store.dispatch('getUserInfo').then(res => {
        memberSelected().then(res => {
          setLocalStrorage('activeClass', res.data[res.data.length - 1])
          this.$router.push(this.$route.query.redirectUrl || '/')
        })
      })
    }
  },
  mounted() {
    //微信扫码登录获取code后登录操作
    let params = getRequestParameters(window.location.href)
    if (params.code && params.state) {
      getUserInfoByWeChatCode(params).then(res => {
        const result = res.data
        if (result.type == 0) {
          ElMessage({
            message: '该账号已冻结',
            type: 'error',
            duration: 5 * 1000
          })
        } else if (result.type == 1) {
          this.inSty(res)
        } else if (result.type == 2) {
          this.formType = 'wechat'
          this.isWechatBindMobile = true
          this.bindId = result.id
        }
      })
    }
  },
  metaInfo() {
    return {
      title: `【${this.sassConfig.name}】登录`,
      meta: [
        {
          name: 'keywords',
          content: `登录`
        },
        {
          name: 'description',
          content: `登录`
        }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
@media screen and (max-width: 1300px) {
  .top-box {
    height: 510px !important;
    justify-content: flex-start !important;
    .header-box {
      padding-bottom: 0px !important;
    }
  }
  .main-big-box {
    height: 400px;
    .left-box {
      margin-left: 94px;
      width: 814px !important;
      height: 382px !important;
    }
  }
  .right-box {
    transform: scale(0.75);
    margin-bottom: -25px !important;
    margin-right: 82px;

    :deep .change-tabs {
      .el-tabs__active-bar {
        left: 27% !important;
      }
    }
  }
  .login-footer-box {
    margin-top: -40px;
    background: #ffffff;
    :deep .footer-light {
      transform: scale(0.85);
      .container {
        padding-top: 8px;
        padding-bottom: 0px;
      }
    }
  }
}
.top-box {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  height: 716px;
  background: linear-gradient(180deg, #354bec 0%, #354bec 0%, #3e94ef 100%);
}
.header-box {
  padding-top: 28px;
  padding-bottom: 65px;
  .left-box {
    display: flex;
    align-items: center;
    span {
      font-size: 16px;
      color: #ffffff;
      margin-left: 12px;
    }
  }
}

.main-big-box {
  position: relative;
  display: flex;
  justify-content: flex-end;
  .left-box {
    position: absolute;
    left: 0px;
    bottom: -1px;
    width: 1163px;
    height: 547px;
    vertical-align: bottom;
  }
  .login-change-sass {
    width: 486px;
    height: 334px;
    padding: 20px 0;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0 30px 40px 0 rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    margin-bottom: 125px;
  }
  .right-box {
    position: absolute;
    width: 400px;
    margin-bottom: 98px;
    bottom: 0;

    .box-card {
      box-shadow: 0 30px 40px 0 rgba(0, 0, 0, 0.1);
      border-radius: 20px;
      h3 {
        font-size: 24px;

        font-weight: bold;
        color: #333333;
        padding: 49px 0 22px 0;
        text-align: center;
        line-height: 100%;
      }

      :deep .login-input {
        .el-input__inner {
          border-radius: 20px;
          padding-left: 45px;
        }

        .el-input__icon {
          font-size: 1.5em;
        }
      }

      :deep .login-tabs {
        .el-tabs__item {
          font-size: 16px;
        }
        .el-tabs__nav-wrap {
          &::after {
            display: none;
          }
        }
        .el-tabs__active-bar {
          width: 30px !important;
          height: 4px;
          left: 14%;
          background: #006df0;
          border-radius: 2px;
        }
      }
      .hint {
        font-size: 14px;
      }
      .text-muted {
        color: #333333;
        font-size: 14px;
      }

      .wechat-logo {
        width: 50px;
        height: 50px;
        cursor: pointer;
      }
      .wechat-login-box {
        display: flex;
        flex-direction: column;
        align-items: center;
        h3 {
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 24px;
            height: 24px;
            margin-right: 14px;
          }
        }
        .bind-des {
          font-size: 14px;
          color: #333333;
          font-weight: bold;
          text-align: center;
          margin-bottom: 32px;
        }
        .qr-view {
          width: 162px;
          height: 162px;
          margin-top: 10px;
          margin-bottom: 20px;
          padding: 8px;
          box-sizing: border-box;
        }
        .hint {
          font-size: 15px;
        }
        .des {
          color: #999999;
          font-size: 12px;
        }
        .agree {
          font-size: 12px;
          color: #333333;
          margin-top: 56px;
          margin-bottom: 42px;
          a {
            color: #006df0;
          }
        }
        .change-box {
          display: flex;
          justify-content: space-between;
          width: 100%;
          span {
            cursor: pointer;
            color: #333333;
            font-size: 16px;
          }
        }
      }

      .impowerBox .title {
        display: none;
      }
      .impowerBox .qrcode {
        margin: 0;
        width: 162px;
        height: 162px;
      }
      .impowerBox .status {
        display: none;
      }
      .impowerBox .info {
        display: none;
      }
    }
  }
}

.qrcode-half {
  position: absolute;
  top: 0;
  right: 0;
  width: 60px;
  height: 60px;
  cursor: pointer;
}

.qr-view {
  display: flex;
  justify-content: center;
  height: 180px;
  margin-top: 45px;
}
</style>

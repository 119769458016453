/*
creator: ZHK
powerBy: zhk930@hotmail.com
*/
import {
	gettype
} from './index.js'
const transForm = function(res) {
	let data = {
		type: gettype(res),
		data: res
	}
	return JSON.stringify(data)
}
const parse = function(res) {
	try {
		return JSON.parse(res).data;
	} catch (e) {
		return '';
	}
}
/*
  本地存储相关工具类
*/
export function setLocalStrorage(name, str) {
	var lstorage = window.localStorage;
	lstorage.setItem(name, transForm(str));
	return str
}
export function getLocalStrorage(name) {
	var lstorage = window.localStorage;
	var _str = lstorage.getItem(name);
	return parse(_str);
}
export function setSessionStrorage(name, str) {
	var sstorage = window.sessionStorage;
	sstorage.setItem(name, transForm(str));
	return str
}
export function getSessionStrorage(name) {
	var sstorage = window.sessionStorage;
	var _str = sstorage.getItem(name);
	return parse(_str);
}

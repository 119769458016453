<template>
  <footer :class="theme === 'dark' ? 'footer-dark' : 'footer-light'" style="line-height: 1.8;" v-if="!size">
    <div class="container w1200">
      <template v-if="isDefaultCorpId">
        <el-row>
          <el-col :span="4">
            <h4 class="p-l-30">帮助中心</h4>
            <el-divider />
            <h5 class="p-l-30">
              <router-link target="_blank" to="/help?helpName=APP下载">
                App下载
              </router-link>
            </h5>
            <h5 class="p-l-30">
              <router-link target="_blank" to="/help?helpName=账号注册">
                账号注册
              </router-link>
            </h5>
            <h5 class="p-l-30">
              <router-link target="_blank" to="/help?helpName=购买课程">
                购买课程
              </router-link>
            </h5>
            <h5 class="p-l-30">
              <router-link target="_blank" to="/help?helpName=如何学习">
                如何学习
              </router-link>
            </h5>
          </el-col>
          <el-col :span="4">
            <h4>关于我们</h4>
            <el-divider />
            <h5>
              <router-link target="_blank" :to="`/login/protocol?type=1&corpId=${corpId}`">
                用户协议
              </router-link>
            </h5>
            <h5>
              <router-link target="_blank" :to="`/login/protocol?type=2&corpId=${corpId}`">
                隐私政策
              </router-link>
            </h5>

            <h5>
              <router-link target="_blank" :to="`/login/protocol?type=3&corpId=${corpId}`">
                免责声明
              </router-link>
            </h5>
          </el-col>
          <el-col :span="7">
            <h4>联系我们</h4>
            <el-divider />
            <h5>客服电话：400-622-9232、400-625-1116</h5>
            <h5 style="white-space:nowrap;">企业邮箱：4006226969@zhongjianedu.com</h5>
            <h5>
              <router-link target="_blank" to="/feedback-list">
                问题反馈
              </router-link>
            </h5>
          </el-col>
          <el-col class="center-box" :span="3">
            <h4>&nbsp;</h4>
            <el-divider />
            <div class="qr-view">
              <img src="@/assets/img/app-qrcode.png" />
            </div>
            <h6>APP二维码</h6>
          </el-col>
          <el-col class="center-box" :span="3">
            <h4>&nbsp;</h4>
            <el-divider />
            <div class="qr-view">
              <img src="@/assets/img/footer/footer_official_accounts_qr.png" />
            </div>
            <h6>扫码关注中建教育</h6>
          </el-col>
          <el-col class="center-box" :span="3">
            <h4>&nbsp;</h4>
            <el-divider />
            <div class="qr-view">
              <img src="@/assets/img/footer/footer_group_qr.png" />
            </div>
            <h6>扫码加入社群</h6>
          </el-col>
        </el-row>
        <div class="row m-t-20 m-b-5">
          <el-divider />
        </div>
      </template>

      <div class="row link-ul m-b-15">
        <ul class="youqing-box">
          <li class="title">友情链接：</li>
          <li>
            <a href="http://www.mohurd.gov.cn/" target="_blank">全国住建部官网</a>
          </li>
          <li>
            <a href="http://jzsc.mohurd.gov.cn/asite/jsbpp/index" target="_blank">全国建筑市场监管公共服务平台</a>
          </li>
          <li>
            <a href="http://www.cpta.com.cn/" target="_blank">中国人事考试网</a>
          </li>
          <li>
            <a href="http://www.mohrss.gov.cn/" target="_blank">中华人民共和国人力资源和社会保障部</a>
          </li>
          <li>
            <a href="http://www.hnrsks.com/" target="_blank">河南人事考试中心</a>
          </li>
        </ul>
      </div>
      <div class="row m-t-20 m-b-0">
        <el-divider />
      </div>
      <div class="row m-t-0 text-center copy-right">
        <p>
          <a class="record" href="http://beian.miit.gov.cn/" target="_blank">豫ICP备09016314号-1</a>
        </p>
      </div>
    </div>
  </footer>
  <div class="footer small" v-if="size === 'small'">
    <div class="nav-list" v-if="isDefaultCorpId">
      <ul>
        <li><a href="">关于我们</a></li>
        <li>┊</li>
        <li><a href="">了解中建</a></li>
        <li>┊</li>
        <li><a href="">加入我们</a></li>
        <li>┊</li>
        <li><a href="">投诉建议</a></li>
        <li>┊</li>
        <li><a href="">免责声明</a></li>
        <li>┊</li>
        <li><a href="">帮助中心</a></li>
        <li>┊</li>
        <li><a href="">售后服务</a></li>
        <li>┊</li>
        <li><a href="">问题反馈</a></li>
        <li>┊</li>
        <li><a href="">APP下载</a></li>
      </ul>
    </div>
    <div class="friendship">
      <ul>
        <li>友情链接：</li>
        <li>
          <a href="http://www.mohurd.gov.cn/" target="_blank">全国住建部官网</a>
        </li>
        <li>
          <a href="http://jzsc.mohurd.gov.cn/asite/jsbpp/index" target="_blank">全国建筑市场监管公共服务平台</a>
        </li>
        <li>
          <a href="http://www.cpta.com.cn/" target="_blank">中国人事考试网</a>
        </li>
        <li>
          <a href="http://www.mohrss.gov.cn/" target="_blank">中华人民共和国人力资源和社会保障部</a>
        </li>
        <li>
          <a href="http://www.hnrsks.com/" target="_blank">河南人事考试中心</a>
        </li>
      </ul>
    </div>
    <a class="record" href="http://beian.miit.gov.cn/" target="_blank">豫ICP备09016314号-1</a>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { defaultCorpId } from '@/utils/index.js'
export default {
  props: {
    size: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      validator: function(t) {
        return t === 'light' || t === 'dark'
      },
      default: 'light'
    }
  },
  computed: {
    ...mapState(['corpId']),
    isDefaultCorpId() {
      return this.corpId == defaultCorpId && !this.$store.getters.isSass
    }
  }
}
</script>

<style lang="scss" scoped>
.footer.small {
  padding: 35px 0 12px 0;
  background-color: #fff;
  .nav-list {
    ul {
      display: flex;
      color: #666666;
      font-size: 12px;
      align-items: center;
      justify-content: center;
      li {
        margin: 2px;
      }
    }
  }
  .friendship {
    margin-top: 10px;
    ul {
      display: flex;
      color: #999999;
      font-size: 12px;
      align-items: center;
      justify-content: center;
      li {
        margin: 2px;
      }
    }
  }
  .record {
    margin-top: 10px;
    color: #999999;
    font-size: 12px;
    text-align: center;
  }
}
.footer-light {
  background: #ffffff;
  .container {
    h4,
    h5,
    h6,
    p {
      color: #666666;
    }
    :deep .el-divider {
      border-top: 1px solid #eeeeee;
    }
    .qr-view {
      background-color: #fff;
      img {
        width: 100px;
        height: 100px;
      }
    }
    .youqing-box {
      .title {
        color: #c2c2c2;
      }
      li + li {
        color: #c2c2c2;
      }
    }
  }
}
.footer-dark {
  background: #333333;
  .container {
    h4,
    h5,
    h6,
    p {
      color: #ffffff;
    }
    :deep .el-divider {
      border-top: 1px solid #5b5b5b;
    }
    .qr-view {
      background-color: #fff;
      img {
        width: 100px;
        height: 100px;
      }
    }
    .youqing-box {
      .title {
        color: #ffffff;
      }
      li + li {
        color: #c2c2c2;
      }
    }
  }
}
.container {
  padding-top: 34px;
  padding-bottom: 24px;
  h4 {
    font-size: 18px;

    font-weight: 400;
  }
  h5 {
    font-size: 14px;

    font-weight: 400;
  }
  .qr-view {
    margin: 0 auto;
    width: 100px;
    display: flex;
    padding: 4px;
    margin-bottom: 10px;
  }
  .center-box {
    text-align: center;
  }
  h6 {
    font-size: 14px;

    font-weight: 400;
  }
  .youqing-box {
    display: flex;
    justify-content: center;
    line-height: 20px;
    li {
      font-size: 12px;

      font-weight: 400;
    }
    li + li {
      margin-left: 10px;
    }
    .title {
      font-size: 14px;
    }
  }
}
</style>

<template>
  <div class="my-container-only-os">
    <slot />
  </div>
</template>

<script>
</script>

<style lang="scss">
.my-container-only-os{
	min-height: 100vh;
	display: flex;
	flex-flow: column;
	.my-main-only-os{
		flex: 1 1 auto;
	}
}
</style>

<template>
	<div class="loadstate-box">
		<!-- 尚未开始请求 -->
		<div class="no-request" v-show="status == 0">
			<slot name="norequest"></slot>
		</div>
		<!-- 请求中 -->
		<div class="loading" v-show="status == 1">
			<svg version="1.1" id="loader-1" xmlns="http://www.w3.org/2000/svg"
				xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="60px" height="60px"
				viewBox="0 0 40 40" enable-background="new 0 0 40 40" xml:space="preserve">
				<path opacity="0.2" fill="#006DF0"
					d="M20.201,5.169c-8.254,0-14.946,6.692-14.946,14.946c0,8.255,6.692,14.946,14.946,14.946
			    s14.946-6.691,14.946-14.946C35.146,11.861,28.455,5.169,20.201,5.169z M20.201,31.749c-6.425,0-11.634-5.208-11.634-11.634
			    c0-6.425,5.209-11.634,11.634-11.634c6.425,0,11.633,5.209,11.633,11.634C31.834,26.541,26.626,31.749,20.201,31.749z" />
				<path fill="#006DF0" d="M26.013,10.047l1.654-2.866c-2.198-1.272-4.743-2.012-7.466-2.012h0v3.312h0
			    C22.32,8.481,24.301,9.057,26.013,10.047z">
					<animateTransform attributeType="xml" attributeName="transform" type="rotate" from="0 20 20"
						to="360 20 20" dur="0.5s" repeatCount="indefinite" />
				</path>
			</svg>
		</div>
		<!-- 请求成功但是没有数据 -->
		<div class="nodata" v-show="status == 2">
			<img src="@/assets/img/study-center/f11dc14281f3faa4e0e4bf6ee07f401.png">
			<slot name="nodata">
				<div class="text">
					暂无数据
				</div>
			</slot>
		</div>
		<!-- 请求成功并且有数据 -->
		<div class="is-finish" v-show="status == 3">
			<slot name="finish">
			</slot>
		</div>
	</div>
</template>

<script>
	export default {
		// 状态说明
		// 0 尚未开始请求
		// 1 请求中
		// 2 请求成功但是没有数据
		// 3 请求成功并且有数据
		props: {
			status: {
				type: [Number, String],
				default: 0
			}
		}
	}
</script>

<style scoped lang="scss">
	.loadstate-box{
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 30px 0;
		.nodata{
			.text{
				margin-top: 15px;
				text-align: center;
				font-size: 16px;
				color: #999999;
			}
		}
	}
</style>

<template>
  <div class="sass-box">
    <h3>请选择校区</h3>
    <div class="content-box">
      <span class="item" :class="currentIndex == index ? 'on' : ''" v-for="(item, index) in sassList" :key="item.id" @click="currentIndex = index">{{
        item.name
      }}</span>
    </div>
    <div class="bottom-box">
      <el-button type="primary" round class="btn" @click="changeSassConfirm">进入</el-button>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { ElMessage } from 'element-plus'
import { findSass, changeSass, memberSelected } from '@/api/index'
import { setLocalStrorage } from '@/utils/storage'
export default {
  data() {
    return {
      sassList: [],
      currentIndex: -1
    }
  },
  computed: {},
  methods: {
    async changeSassConfirm() {
      if (this.currentIndex == -1) {
        return ElMessage.error('请选择校区')
      }
      const currentSass = this.sassList[this.currentIndex]
      let { data: changeSassData } = await changeSass({ corpId: currentSass.id })
      this.$store.commit('SER_TOKEN', changeSassData)
      this.$store.commit('SET_CORPID', changeSassData.corpId)

      let protocol = ''
      let domains = ['cloud.zhongjianedu.com', 'zhongjianedu.com', 'www.zhongjianedu.com', 'x.zhongjianedu.com']
      const index = domains.findIndex(item => item == changeSassData.domain)
      protocol = index == -1 ? 'http:' : 'https:'
      this.$emit('change')
      window.close()
      window.open(`${protocol}//${currentSass.domain}${window.location.pathname}${window.location.search}`, '_blank')
    }
  },
  created() {
    findSass({}).then(res => {
      this.sassList = res.data
      this.$store.commit('SET_SASSLIST', this.sassList)
    })
  }
}
</script>
<style scoped lang="scss">
.sass-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  h3 {
    color: #333;
    font-size: 16px;
    padding-left: 20px;
  }
  .content-box {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    margin-top: 40px;
    padding-left: 20px;
    .item {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 140px;
      height: 32px;
      margin-right: 24px;
      margin-bottom: 14px;
      background: #ffffff;
      border-radius: 20px;
      border: 1px solid #e6e6e6;
      color: #666;
      font-size: 14px;
      cursor: pointer;
      &.on {
        color: #096df0;
        border: 1px solid #096df0;
      }
    }
  }
  .bottom-box {
    display: flex;
    justify-content: center;
    width: 100%;
    padding-top: 20px;
    border-top: 1px solid #e6e6e6;
    .btn {
      width: 320px;
      height: 34px;
    }
  }
}
</style>

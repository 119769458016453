import {
    createApp
} from 'vue'
const app = createApp(App)
// import 'default-passive-events'
import '@/style/element-variables.scss';
import ElementPlus from 'element-plus';

import '@/assets/font/iconfont.css';
import '@/assets/font/iconfont.js';
import registerSvgIcon from './icons';
import NProgress from 'nprogress'
NProgress.configure({
    showSpinner: false
});
import 'nprogress/nprogress.css'
import App from './App.vue'
import router from './router'
import store from './store'

import * as filters from './filters/index.js'

import MetaInfo from 'vue-meta-info';


// 统一导入el-icon图标
import * as ElIconModules from '@element-plus/icons'
//放全局
app.config.globalProperties.$icon = ElIconModules;
// 统一注册el-icon图标
for (let iconName in ElIconModules) {
    app.component(transElIconName(iconName), ElIconModules[iconName])
}

// utils/utils.js
// 将el-icon的组件名称AbbbCddd转化为i-abbb-cddd形式
// 此前用switch做组件名时因关键字重复报错，所以格式统一加了前缀
// 例：Switch转换为i-switch，ArrowDownBold转换为i-arrow-down-bold
export function transElIconName(iconName) {
    return 'i' + iconName.replace(/[A-Z]/g, (match) => '-' + match.toLowerCase())
}



//代替$on $emit
import mitt from '@/assets/js/mitt.js'
window.$mitt = mitt();
//几个全局组件 不会多犯不着写个文件了
import zjContainer from '@/components/zj-container.vue'
import zjMain from '@/components/zj-main.vue'
import zjLoadstate from '@/components/loadstate/index.vue'
import zjPage from '@/components/page.vue'
import RightFixedBar from '@/components/right-fixed-bar.vue'
app.component('zjContainer', zjContainer)
app.component('zjMain', zjMain)
app.component('zjLoadstate', zjLoadstate)
app.component('zjPage', zjPage)
app.component('RightFixedBar', RightFixedBar)

registerSvgIcon(app)

const whitelist = ['/login/protocol', '/help','/alliance']
//简单混入后期东西多了可以单独分个文件出来
app.mixin({
    methods: {
        // { obj
        // 	path: path,
        // 	query: query || {}
        // }
        $windowOpen(obj = {}) {
            window.open(router.resolve(obj).href, '_blank')
        }
    }
});
//全局导航钩子
router.beforeEach(async (to, from, next) => {
    NProgress.start();
    if (!store.state.sassConfig.id) {
		await store.dispatch('getsassConfig').then((res) => {})
	}
    //后面写验证登录权限无权限跳转到登录页
    //现在逻辑全都需要登录
    if (!store.state.token && to.path != '/login' && whitelist.indexOf(to.path) == -1) {
        return next(`/login?redirectUrl=${encodeURIComponent(to.fullPath)}`)
    }
    next();
});
router.afterEach(transition => {
    NProgress.done();
});
















app.config.globalProperties.$filters = filters;
app.use(store).use(router).use(MetaInfo).use(ElementPlus).mount('#app')

import axios from 'axios'
import {
	ElMessage,
	ElMessageBox
} from 'element-plus'
import store from '@/store'
import {
	getToken
} from '@/utils/auth'
import {
	getLocalStrorage,
	setLocalStrorage
} from '@/utils/storage.js'
import {
	uuid,
	clear
} from '@/utils/index.js'

// create an axios instance
const service = axios.create({
	baseURL: process.env.VUE_APP_BASE_API, // url = base url + request url
	// withCredentials: true, // send cookies when cross-domain requests
	timeout: 150000 // request timeout
})

function getDefaultHeader() {
  return {
    dvcType: '1',
    clientType: '0',
    dvcId: getLocalStrorage('UUID') || setLocalStrorage('UUID', uuid()),
    corpId: store.state.corpId,
    Authorization: store.state.token ? store.state.token : ''
  }
}

// request interceptor
service.interceptors.request.use(
  config => {
    // config.headers['dvcType'] = '1'
    // config.headers['dvcId'] = getUUID()
    // config.headers['corpId'] = store.state.corpId
    // if (store.state.token) {
    //   config.headers['Authorization'] = store.state.token
    // }
    let headersObj = getDefaultHeader()
    Object.keys(headersObj).forEach(item => {
      if (headersObj[item]) {
        config.headers[item] = headersObj[item]
      }
    })
    config.data = {
      corpId: store.state.corpId,
      ...config.data
    }
    config.data = clear(config.data)
    return config
  },
  error => {
    // console.log(error)
    return Promise.reject(error)
  }
)
//过滤
const fliters = function(res){
	if (res.code == 403) {
		ElMessageBox.alert('访问受限，请联系管理员',
			'提示', {
				confirmButtonText: '确定'
			})
	}
	if (res.code == 401 || res.code == 402) {
		ElMessageBox.alert('登录已过期请重新登录',
			'提示', {
				confirmButtonText: '确定',
				callback: res => {
					store.dispatch('resetToken').then(() => {
						location.reload()
					})
				}
			})
	}
	if (res.code == 500) {
		ElMessage({
			message: res.msg,
			type: 'error',
			duration: 5 * 1000
		})
	}
	if (res.code == 504) {
		ElMessage({
			message: '网络小波动，请稍后重试',
			type: 'error',
			duration: 5 * 1000
		})
	}
}
// response interceptor
service.interceptors.response.use(
	response => {
		fliters(response.data)
		if(response.data.code != 200){
			return Promise.reject(response.data)
		}
		return response.data
	},
	error => {
		try{
			fliters(error.response.data)
		}catch(e){
			fliters({code:504})
		}
		
		return Promise.reject(error)
	}
)

export default service

//网页关闭时请求
export function fetchRequest(params) {
  // 发送请求
  return fetch(params.url, {
    method: params.method,
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
      ...getDefaultHeader()
    },
    keepalive: true,
    mode: 'cors',
    body: JSON.stringify(params.body)
  })
    .then(response => {
      return Promise.resolve(response)
    })
    .catch(reason => {
      return Promise.reject(reason)
    })
    .finally(res => {})
}

<!-- 右侧悬浮条 -->
<template>
  <div class="fixed-bar-box">
    <div class="content">
      <div class="fixed-item app-qrcode cursor-pointer" @mouseenter="isShowQRCode = true" @mouseleave="isShowQRCode = false">
        <img src="@/assets/img/fixed-bar/app-normal.png" alt="" />
        <span>APP下载</span>
      </div>
      <!-- <div class="fixed-item cursor-pointer" onclick="window.open('https://p.qiao.baidu.com/cps/chat?siteId=17341031&userId=25726825&siteToken=4520afb25943e91ec97fba73a548b02d', '_blank', 'height=600,width=800,top=50,left=200,status=yes,toolbar=no,menubar=no,resizable=no,scrollbars=no,location=no,titlebar=no')">
        <img src="@/assets/img/fixed-bar/consultant-normal.png" alt="" />
        <span>课程咨询</span>
      </div> -->
      <div class="fixed-item cursor-pointer" @click="scrollTopClick">
        <img src="@/assets/img/fixed-bar/top-normal.png" alt="" />
        <span>返回顶部</span>
      </div>
      <div class="qrcode-box" :class="isShowQRCode ? 'show' : ''">
        <img src="@/assets/img/app-qrcode.png" alt="" />
        <span class="text-primary">随时随地 听课刷题</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'RightFixedBar',
  data() {
    return {
      isShowQRCode: false,
      isShowScrollTop: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true)
  },

  methods: {
    handleScroll() {
      let scrolltop = document.documentElement.scrollTop || document.body.scrollTop
      this.isShowScrollTop = scrolltop >= 50
    },
    scrollTopClick() {
      let top = document.documentElement.scrollTop || document.body.scrollTop
      // 实现滚动效果
      const timeTop = setInterval(() => {
        document.body.scrollTop = document.documentElement.scrollTop = top -= 50
        if (top <= 0) {
          clearInterval(timeTop)
        }
      }, 10)
    }
  }
}
</script>

<style lang="scss" scoped>
.fixed-bar-box {
  position: fixed;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  margin-right: 34px;
  z-index: 9999;

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    .fixed-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 74px;
      height: 70px;
      background: #ffffff;
      transition: all 0.3s;
      color: #333333;
      span {
        font-size: 12px;
      }
      &:hover {
        background: $primary-color;
        color: #ffffff;
      }
      &:nth-child(1):hover {
        img {
          content: url('~@/assets/img/fixed-bar/app-selected.png');
        }
      }
      &:nth-child(2):hover {
        img {
          content: url('~@/assets/img/fixed-bar/consultant-selected.png');
        }
      }
      &:nth-child(3):hover {
        img {
          content: url('~@/assets/img/fixed-bar/top-selected.png');
        }
      }
    }

    .qrcode-box {
      position: absolute;
      left: -150px;
      top: 0;
      width: 150px;
      height: 180px;
      display: none;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      transition: all 0.3s;
      box-shadow: 0px 3px 6px rgba(0, 0, 0, 0);
      img {
        width: 114px;
        height: 114px;
      }
      span {
        font-size: 12px;
        margin-top: 12px;
      }
    }
    .show {
      display: flex;
    }
  }
}
</style>

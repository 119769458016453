<template>
  <footer class="footer-light" style="line-height: 1.8;" v-if="!size">
    <div class="container w1200">
      <el-row v-if="isDefaultCorpId">
        <el-col :span="4">
          <h5>
            <router-link target="_blank" :to="`/login/protocol?type=1&corpId=${corpId}`">
              用户协议
            </router-link>
          </h5>
          <h5>
            <router-link target="_blank" :to="`/login/protocol?type=2&corpId=${corpId}`">
              隐私政策
            </router-link>
          </h5>

          <h5>
            <router-link target="_blank" :to="`/login/protocol?type=3&corpId=${corpId}`">
              免责声明
            </router-link>
          </h5>
        </el-col>
        <el-col :span="10">
          <h5>
            <router-link target="_blank" to="/help">
              帮助中心
            </router-link>
          </h5>
          <h5>客服电话：400-622-9232、400-625-1116</h5>
          <h5 style="white-space:nowrap;">企业邮箱：4006226969@zhongjianedu.com</h5>
        </el-col>
        <el-col class="qr-box" :span="10">
          <div class="center-box">
            <div class="qr-view">
              <img src="@/assets/img/app-qrcode.png" />
            </div>
            <h6>APP二维码</h6>
          </div>
          <div class="center-box">
            <div class="qr-view">
              <img src="@/assets/img/footer/footer_official_accounts_qr.png" />
            </div>
            <h6>扫码关注中建教育</h6>
          </div>
          <div class="center-box">
            <div class="qr-view">
              <img src="@/assets/img/footer/footer_group_qr.png" />
            </div>
            <h6>扫码加入社群</h6>
          </div>
        </el-col>
      </el-row>

      <div class="row m-t-0 text-center copy-right">
        <p>
          <a class="record" href="http://beian.miit.gov.cn/" target="_blank">豫ICP备09016314号-1</a> &nbsp;&nbsp;&nbsp;&nbsp; |
          &nbsp;&nbsp;&nbsp;&nbsp; v.{{ $store.state.version }}
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
import { mapState } from 'vuex'
import { defaultCorpId } from '@/utils/index.js'
export default {
  props: {
    size: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      validator: function(t) {
        return t === 'light' || t === 'dark'
      },
      default: 'light'
    }
  },
  computed: {
    ...mapState(['corpId']),
    isDefaultCorpId() {
      return this.corpId == defaultCorpId && !this.$store.getters.isSass
    }
  }
}
</script>

<style lang="scss" scoped>
.footer-light {
  background: #ffffff;
  .el-row {
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 15px;
  }
  .container {
    h4,
    h5,
    h6,
    p {
      color: #666666;
    }
    :deep .el-divider {
      border-top: 1px solid #eeeeee;
    }
    .qr-view {
      background-color: #fff;
      img {
        width: 88px;
        height: 88px;
      }
    }
    .youqing-box {
      .title {
        color: #c2c2c2;
      }
      li + li {
        color: #c2c2c2;
      }
    }
  }
}

.container {
  padding-top: 34px;
  padding-bottom: 15px;
  h4 {
    font-size: 18px;

    font-weight: 400;
  }
  h5 {
    font-size: 14px;

    font-weight: 400;
  }
  .qr-box {
    display: flex;
    justify-content: space-between;
  }
  .qr-view {
    margin: 0 auto;
    display: flex;
    padding: 4px;
  }
  .copy-right {
    padding: 15px 0;
  }
  .center-box {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h6 {
    font-size: 14px;

    font-weight: 400;
  }
  .youqing-box {
    display: flex;
    justify-content: center;
    line-height: 20px;
    li {
      font-size: 12px;

      font-weight: 400;
    }
    li + li {
      margin-left: 10px;
    }
    .title {
      font-size: 14px;
    }
  }
}
</style>

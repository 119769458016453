<template>
  <div class="change-sass-container">
    <el-dialog v-model="isShow" width="486px" destroy-on-close>
      <change-sass @change="isShow = false"></change-sass>
    </el-dialog>
  </div>
</template>
<script>
import { fa } from 'element-plus/lib/locale'
import ChangeSass from './change-sass.vue'
export default {
  data() {
    return {
      isShow: false
    }
  },
  components: {
    ChangeSass
  },
  methods: {
    open() {
      this.isShow = true
    }
  }
}
</script>
<style scoped lang="scss">
.change-sass-container {
  :deep .el-dialog {
    .el-dialog__header {
      display: none;
    }
    .el-dialog__body {
      padding: 20px 0;
      height: 334px;
      box-sizing: border-box;
    }
  }
}
</style>

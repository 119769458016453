<template>
  <div class="header-container">
    <div class="top-header">
      <div class="w1200">
        <div class="change-sass">
          <!-- 去掉该功能 -->
          <!-- <p v-if="sassList.length > 1">
            校区：<span>{{ currentSass.name }}</span> <i @click="$refs.changeSassRef.open()">[切换校区]</i>
          </p> -->
        </div>
        <div style="position: relative;">
          <template v-if="!$store.getters.isSass">
            <div class="li"><b style="color:black">客服电话：400-622-9232、400-625-1116</b></div>
            <a class="fudongd" href="/employeeinquiry" target="_blank"><b style="color:black">员工身份核验</b></a>
          </template>
        </div>
      </div>
    </div>
    <div style="height: 88px;" id="headerBox">
      <div class="header-box" :class="{ fixed: isfixed }">
        <div class="w1200 bottom-header">
          <div class="left-box">
            <template v-if="isDefaultCorpId">
              <img class="cursor-pointer" style="width:142px;height:38px" @click="$router.push('/')" src="@/assets/logo.png" />
            </template>
            <template v-else>
              <img :src="currentSass.logo" alt="" style="width:36px;height:36px" />
              <span>{{ currentSass.name }}</span>
            </template>
          </div>
          <div class="center-box">
            <div class="nav-list">
              <router-link class="li" :class="{ on: $route.path === '/public' || $route.path === '/public/classdetails' }" to="/public"
                >公开课</router-link
              >
              <router-link class="li" :class="{ on: $route.path === '/live' }" to="/live">直播课</router-link>
              <router-link class="li" :class="{ on: $route.path === '/elective' || $route.path === '/elective/details' }" to="/elective"
                >选课中心</router-link
              >
              <router-link class="li topic" to="/learn/topic">题库中心</router-link>
              <!-- <a class="li" href="" target="_blank">图书教材</a> -->
              <!-- <a class="li" href="http://zl.zhongjianedu.com/ziliaoku/list_114_1.html" target="_blank">资料下载</a> -->
            </div>
          </div>
          <div class="right-box">
            <div class="button-nav-box">
              <router-link class="button" to="/learn">学习中心</router-link>
              <div class="line" v-if="isSelectedStudyCenter"></div>
            </div>
            <div class="user-info" v-if="token">
              <div class="img-box">
                <el-popover placement="bottom" trigger="click">
                  <span class="span-1 cursor-pointer" @click="geren">个人设置</span>
                  <span class="span-1 cursor-pointer" @click="xiaoxi">消息中心</span>
                  <span class="span-1 cursor-pointer" @click="tuichu">退出</span>
                  <template #reference>
                    <img class="cursor-pointer" :src="userInfo.icon" @click="icon" />
                  </template>
                </el-popover>
              </div>
              <div class="name cl1">{{ userInfo.nickName }}</div>
            </div>
            <div class="login-box" v-else>
              <div class="button">
                <router-link target="_blank" to="/login">登录</router-link>
                |
                <router-link target="_blank" to="/login">注册</router-link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <change-sass-dialog ref="changeSassRef"></change-sass-dialog>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import $ from 'jquery'
import ChangeSassDialog from './change-sass/change-sass-dialog.vue'
import { defaultCorpId } from '@/utils/index.js'
export default {
  computed: {
    ...mapState(['corpId', 'userInfo', 'token', 'sassConfig', 'sassList']),
    currentSass() {
      let sass = this.sassList.find(item => item.id == this.corpId)
      //没有匹配到加盟商
      if (!sass) {
        sass = {
          logo: this.sassConfig.logo || 'https://zj-web-source.oss-cn-beijing.aliyuncs.com/app/logo-icon.png',
          name: this.sassConfig.name
        }
      } else {
        //加盟商logo 为空
        if (!sass.logo) {
          sass.logo = 'https://zj-web-source.oss-cn-beijing.aliyuncs.com/app/logo-icon.png'
        }
      }
      return sass
    },
    isDefaultCorpId() {
      return this.corpId == defaultCorpId && !this.$store.getters.isSass
    }
  },
  components: {
    ChangeSassDialog
  },
  data() {
    return {
      isfixed: false,
      isSelectedStudyCenter: false
    }
  },
  watch: {
    '$route.path': {
      handler(newVal, oldVal) {
        //学习中心 帮助中心 题库
        if (
          newVal.indexOf('/learn/') != -1 ||
          newVal.indexOf('/help') != -1 ||
          newVal.indexOf('/practice') != -1 ||
          newVal.indexOf('/introduce') != -1 ||
          newVal.indexOf('/score-report') != -1 ||
          newVal.indexOf('/exam') != -1
        ) {
          this.isSelectedStudyCenter = true
        } else {
          this.isSelectedStudyCenter = false
        }
      },
      deep: true,
      immediate: true
    }
  },
  created() {
    $(window).scroll(() => {
      if (!$('#headerBox')[0]) {
        return
      }
      if ($(document).scrollTop() - $('#headerBox').offset().top >= 0) {
        this.isfixed = true
      } else {
        this.isfixed = false
      }
    })
  },
  methods: {
    // routerActive(path) {
    //   return !(window.location.pathname.indexOf(path) == -1)
    // },
    geren() {
      this.$router.push('/learn/personal-setting')
    },
    xiaoxi() {
      this.$router.push('/learn/message-center')
    },
    tuichu() {
      this.$store.dispatch('resetToken')
      this.$router.push('/login')
    }
  }
}
</script>

<style lang="scss" scoped>
.top-header {
  overflow: hidden;
  .w1200 {
    font-size: 14px;

    font-weight: 400;
    color: #666666;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .change-sass {
      font-size: 14px;
      color: #999;
      span {
        color: #333333;
      }
      i {
        color: #4c7af2;
        cursor: pointer;
      }
    }
    .fudongd{
        position: absolute;
        right: -120px;
      }
    .li {
      display: inline-block;
    }
    .li + .li {
      margin-left: 60px;
    }
  }
}

.header-box {
  &.fixed {
    width: 100%;
    position: fixed;
    top: 0;
    z-index: 2000;
  }
  padding: 22px 0;
  background: #ffffff;
  box-shadow: 0px 4px 21px 0px rgba(202, 206, 216, 0.6);

  .bottom-header {
    display: flex;
    align-items: center;

    .left-box {
      display: flex;
      align-items: center;
      span {
        font-size: 16px;
        color: #333;
        font-weight: bold;
        margin-left: 6px;
      }
    }

    .center-box {
      flex: 1 1 auto;
      margin-left: 59px;

      .nav-list {
        display: flex;
        font-size: 16px;

        font-weight: 400;
        color: #333333;

        .li + .li {
          margin-left: 60px;
        }

        .li {
          position: relative;

          &.router-link-active,
          &.on {
            color: $primary-color;

            &:after {
              content: '';
              position: absolute;
              width: 30px;
              height: 4px;
              background: $primary-color;
              border-radius: 2px;
              left: 50%;
              transform: translateX(-50%);
              bottom: -11px;
            }
          }
          &.topic {
            color: #333333;
            &:after {
              display: none;
            }
          }
        }
      }
    }

    .right-box {
      display: flex;
      align-items: center;

      .button-nav-box {
        position: relative;
        margin-right: 60px;

        .button {
          display: block;
          width: 80px;
          height: 30px;
          background: linear-gradient(94deg, #3696fd, #005bea);
          border-radius: 15px;
          font-size: 14px;

          font-weight: 400;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .line {
          position: absolute;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
          width: 30px;
          height: 4px;
          margin-bottom: -12px;
          background: #006df0;
          border-radius: 2px;
        }
      }

      .user-info {
        display: flex;
        align-items: center;

        .img-box {
          display: flex;

          img {
            width: 40px;
            height: 40px;
            border-radius: 50%;
          }
        }

        .name {
          width: 100px;
          margin-left: 8px;
          font-size: 14px;

          font-weight: 400;
          color: #666666;
        }
      }

      .login-box {
        .button {
          width: 80px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 14px;

          font-weight: 400;
          color: $primary-color;
          border-radius: 50px;
          border: 1px solid $primary-color;
        }
      }
    }
  }
}

.span-1 {
  display: block;
  margin-bottom: 16px;
  font-size: 15px;

  font-weight: 400;
  color: #333333;
  line-height: 17px;
  text-align: center;
}

.span-1:hover {
  color: #006df0;
}

:deep .el-popper__arrow {
  display: none;
}

:deep .el-popover {
  width: 70px;
}
</style>

<template>
	<component :is="componentName" :info="info"></component>

</template>

<script>
	import list1 from './list1.vue'
	import list2 from './list2.vue'

	export default {
		props:{
			info:{
				type:Object,
				default(){
					return {}
				}
			}
		},
		computed:{
			componentName(){
				if(this.info.free){
					return 'list1'
				}else{
					return 'list2'
				}
			}
		},
		created(){
			
		},
		components:{
			list1,list2
		}
	}
</script>

<style>
</style>

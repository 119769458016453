import { createRouter, createWebHistory } from 'vue-router'
import loginIndex from '../views/login/index.vue'
import publicClassIndex from '../views/publicClass/index.vue'
import store from '@/store'

//兼容sass代码
const sassRoutes = [
  {
    path: '/',
    redirect: '/alliance'
  },
  {
    path: '/public',
    component: publicClassIndex,
    meta: {
      keepAlive: true
    }
  }
]
const platform = [
  {
    path: '/',
    alias: '/public',
    component: publicClassIndex,
    meta: {
      keepAlive: true
    }
  }
]
const mergeRoutes = store.getters.isSass ? sassRoutes : platform
const routes = [
  ...mergeRoutes,
  {
    path: '/login',
    name: 'login',
    component: loginIndex
  },
  {
    path: '/login/protocol',
    component: () => import('../views/login/protocol.vue')
  },
  {
    path: '/public/classdetails',
    component: () => import('../views/publicClass/details.vue')
  },
  {
    path: '/live',
    component: () => import('../views/live/index.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/elective',
    component: () => import('../views/elective/index.vue'),
    meta: {
      keepAlive: true
    }
  },
  {
    path: '/elective/details',
    component: () => import('../views/elective/details.vue')
  },
  {
    path: '/buy',
    component: () => import('../views/buy/index.vue')
  },
  {
    path: '/buy/payment',
    component: () => import('../views/buy/payment.vue')
  },
  {
    path: '/buy/wxpay',
    component: () => import('../views/buy/wxpay.vue')
  },
  {
    path: '/courseqaDetails',
    component: () => import('../views/learn/Coursedelis.vue')
  },
  {
    path: '/Coursedelis',
    component: () => import('../views/learn/Coursedelis.vue')
  },
  {
    path: '/help',
    component: () => import('../views/help')
  },
  {
    path: '/video',
    component: () => import('../views/video/video.vue')
  },

  {
    path: '/living',
    component: () => import('../views/video/living.vue')
  },

  {
    path: '/learn',
    redirect: '/learn/curric',
    component: () => import('../views/learn/components/layout.vue'),
    children: [
      // {
      // 	path: '',
      // 	component: () => import('../views/learn/index/index.vue')
      // },
      {
        path: 'order',
        component: () => import('../views/learn/order/index.vue')
      },
      {
        path: 'order/detail',
        component: () => import('../views/learn/order/detail.vue'),
        meta: { activeMenu: '/learn/order' }
      },
      {
        path: 'order/logistics-list',
        component: () => import('../views/learn/order/logistics-list.vue'),
        meta: { activeMenu: '/learn/order', requireAuth: true }
      },
      {
        path: 'teacher',
        component: () => import('../views/learn/teacher'),
        meta: { requireAuth: true }
      },
      {
        path: 'invoice',
        component: () => import('../views/learn/invoice/index.vue')
      },
      {
        path: 'receipts',
        component: () => import('../views/learn/receipts.vue')
      },
      {
        path: 'address-manage',
        component: () => import('../views/learn/address-manage.vue')
      },
      {
        path: 'personal-setting',
        component: () => import('../views/learn/personal-setting.vue')
      },
      {
        path: 'message-center',
        component: () => import('../views/learn/message-center.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: 'StudyCenterStr',
        component: () => import('../views/learn/StudyCenterStr.vue')
      },
      {
        path: 'curric',
        component: () => import('../views/learn/curric')
      },
      {
        path: 'curric/contents',
        component: () => import('../views/learn/curric/contents.vue'),
        meta: { activeMenu: '/learn/curric' }
      },
      {
        path: 'curric/ftfcontents',
        component: () => import('../views/learn/curric/ftfcontents.vue'),
        meta: { activeMenu: '/learn/curric' }
      },
      {
        path: 'curric/material',
        component: () => import('../views/learn/curric/material.vue'),
        meta: { activeMenu: '/learn/curric' }
      },
      {
        path: 'course-history',
        component: () => import('../views/learn/course-history.vue')
      },
      {
        path: 'StudyCenterMyVlog',
        component: () => import('../views/learn/StudyCenterMyVlog.vue'),
        meta: { activeMenu: '/learn/curric' }
      },
      {
        path: 'course-qa',
        component: () => import('../views/learn/course-qa.vue')
      },
      {
        path: 'course-qa-ask',
        component: () => import('../views/learn/course-qa-ask.vue'),
        meta: { activeMenu: '/learn/course-qa' }
      },
      {
        name: 'notify-detail',
        path: 'notify-detail',
        component: () => import('../views/learn/notify-detail.vue'),
        meta: { activeMenu: '/learn/message-center' }
      },
      {
        path: 'topic',
        component: () => import('../views/learn/topic/index.vue'),
        meta: {
          keepAlive: true
        }
      },
      {
        path: 'record',
        component: () => import('../views/learn/record/index.vue'),
        meta: {
          keepAlive: true
        }
      }
    ]
  },
  {
    path: '/feedback',
    component: () => import('../views/feedback/index.vue')
  },
  {
    path: '/feedback-list',
    component: () => import('../views/feedback/list.vue')
  },
  {
    path: '/feedback-detail',
    component: () => import('../views/feedback/detail.vue')
  },
  {
    path: '/practice',
    name: 'practice',
    component: () => import('../views/bank/practice/index.vue')
  },
  {
    path: '/practice-parse',
    component: () => import('../views/bank/practice-parse/index.vue')
  },
  {
    path: '/exam',
    component: () => import('../views/bank/exam/index.vue')
  },
  {
    path: '/exam-practice',
    component: () => import('../views/bank/exam-practice/index.vue')
  },
  {
    path: '/introduce',
    component: () => import('../views/bank/introduce/index.vue')
  },
  {
    path: '/score-report',
    component: () => import('../views/bank/score-report/index.vue')
  },
  {
    path: '/exam-parse',
    component: () => import('../views/bank/exam-parse/index.vue')
  },
  {
    path: '/exam-practice-parse',
    component: () => import('../views/bank/exam-practice-parse/index.vue')
  },
  {
    path: '/signup-exam',
    component: () => import('../views/signup-exam/signup-exam.vue')
  },
  {
    path: '/alliance',
    component: () => import('../views/alliance/index.vue')
  },
  {
    path: '/employeeinquiry',
    component: () => import('@/views/employeeinquiry/index.vue'),
    meta: {
      requireAuth: false
    }
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        top: 0
      }
    }
  }
})

export default router

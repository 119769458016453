<template>
  <div class="com-page">
    <el-pagination hide-on-single-page layout="prev, pager, next,jumper,slot" next-text="下一页" prev-text="上一页" :page-size="postData.pageSize" :current-page="postData.pageNo" :total="total" @current-change="currentChange">
    </el-pagination>
  </div>
</template>

<script>
import * as apis from '@/api/index.js'
import { gettype, getRequestParameters, debounce } from '@/utils/index.js'
export default {
  props: {
    value: {
      type: Array,
      default() {
        return []
      }
    },
    url: {
      type: String,
      default: ''
    },
    about: {
      type: Boolean,
      default: true
    }
  },
  emits: ['statusChange', 'total', 'recordCurrentPage', 'update:modelValue', 'onUpdate:currentPage'],
  data() {
    return {
      urlName: '',
      // 状态说明
      // 0 尚未开始请求
      // 1 请求中
      // 2 请求成功但是没有数据
      // 3 请求成功并且有数据
      status: 0,
      total: 99,
      postData: {
        pageIndex: 1,
        pageNo: 1,
        pageSize: 10
      }
    }
  },
  watch: {
    url: {
      handler(val, oldVal) {
        this.parsing()
      },
      deep: true,
      immediate: true
    },
    postData: {
      handler(val, oldVal) {
        if (this.about) {
          this.Aajax()
        }
      },
      deep: true
    }
  },
  created() {
    this.$emit('statusChange', this.status)
  },
  mounted() {},
  methods: {
    currentChange(res) {
      this.postData.pageIndex = res
      this.postData.pageNo = res
      this.$emit('recordCurrentPage', res)
    },
    // 解析url地址
    parsing() {
      if (typeof this.url !== 'undefined') {
        const postData = getRequestParameters(this.url)
        this.urlName = this.url.split('?')[0]
        postData.pageIndex = (postData.pageIndex || 1) * 1
        postData.pageNo = (postData.pageNo || 1) * 1
        postData.pageSize = (postData.pageSize || 10) * 1
        setTimeout(() => {
          this.postData = postData
        }, 1)
      }
    },

    Aajax: debounce(function () {
      this.$emit('statusChange', 1)
      this.$emit('update:modelValue', [])
      apis[this.urlName](this.postData)
        .then(res => {
          if (res.data.data) {
            if (gettype(res.data.data === '[object Array]')) {
              if (res.data.data.length) {
                this.$emit('statusChange', 3)
              } else {
                this.$emit('statusChange', 2)
              }
              this.$emit('update:modelValue', res.data.data)
              this.total = res.data.recCount
              this.$emit('total', this.total)
            }
          } else {
            if (gettype(res.data.rows === '[object Array]')) {
              if (res.data.rows.length) {
                this.$emit('statusChange', 3)
              } else {
                this.$emit('statusChange', 2)
              }
              this.$emit('update:modelValue', res.data.rows)
              this.total = Number(res.data.total)
              this.$emit('total', this.total)
            }
          }
        })
        .catch(error => {
          this.$emit('statusChange', 2)
        })
    }, 500)
  }
}
</script>

<style scoped lang="scss">
.com-page {
  :deep .el-pagination {
    background: none;

    .btn-prev,
    .btn-next,
    .number {
      background: none;
      border: 1px solid #dfdfdf;
    }

    .number.active {
      border-color: $primary-color;
    }

    .btn-prev,
    .btn-next {
      padding: 0 12px;
    }

    .btn-prev {
      margin-right: 10px;
    }

    .btn-next {
      margin-left: 10px;
    }

    .el-pager li {
      margin: 0 6px;
    }

    .more {
      background: none;
    }

    .el-pagination__jump {
      .el-input__inner {
        background: none;
      }
    }
  }
}
</style>

import { createStore } from 'vuex'
import { setLocalStrorage, getLocalStrorage } from '@/utils/storage.js'
import { setToken, getToken } from '@/utils/auth.js'
import { defaultCorpId } from '@/utils/index'
import { getNewToken, getUserInfo, getClassList, getUserDict, sassAuthDomain } from '@/api/index.js'
import config from '../../package.json'
import getters from './getters'
const TOKEN = function() {
  return getLocalStrorage('TOKEN') || ''
}
const USERINFO = function() {
  return getLocalStrorage('USERINFO') || {}
}
const CLASSLIST = function() {
  return getLocalStrorage('CLASSLIST') || []
}
const EXAMJUMPTYPE = function() {
  return getLocalStrorage('EXAMJUMPTYPE') || []
}
const USERDICT = function() {
  return getLocalStrorage('userDict') || {}
}
const SASSLIST = function() {
  return getLocalStrorage('SASSLIST') || []
}
const isProduction = (function() {
  if (process.env.NODE_ENV === 'development') {
    return false
  }
  if (['cloud.zhongjianedu.com'].includes(window.location.hostname)) {
    return false
  }
  if (window.location.hostname.indexOf('.sass-test.zhongjianedu.com') !== -1) {
    return false
  }
  return true
})()
export default createStore({
  state: {
    isProduction: isProduction,
    sassSuffix: isProduction ? '.sass.zhongjianedu.com' : '.sass-test.zhongjianedu.com',
    version: config.version,
    //token
    token: TOKEN() ? TOKEN().token : '',
    //用户信息
    userInfo: USERINFO(),
    //所有分类
    classList: CLASSLIST(),
    //加盟商信息
    sassConfig: {},
    //企业唯一识别符
    corpId: defaultCorpId,
    //全局通用发送验证码倒计时
    codeTime: 60,
    //记录练习模式和考试模式页面跳转来源
    examJumpType: EXAMJUMPTYPE(),
    //用户字典
    userDict: USERDICT(),
    //校区列表
    sassList: SASSLIST()
  },
  mutations: {
    //设置验证码倒计时还剩多少秒
    SET_CODETIME(state, res) {
      state.codeTime = res
    },
    //储存token信息
    SER_TOKEN(state, res) {
      setLocalStrorage('TOKEN', res)
      state.token = res.token
    },
    //设置用户信息
    SET_USER_INFO(state, res) {
      setLocalStrorage('USERINFO', res)
      state.userInfo = res
    },
    //储存所有分类列表
    SET_CLASSLIST(state, res) {
      setLocalStrorage('CLASSLIST', res)
      state.classList = res
    },
    //设置练习模式和考试模式页面跳转来源
    SET_EXAMJUMPTYPE(state, res) {
      setLocalStrorage('EXAMJUMPTYPE', res)
      state.examJumpType = res
    },
    //用户字典
    SET_URSER_DICT(state, res) {
      setLocalStrorage('userDict', res)
      state.userDict = res
    },
    //存储sasslist
    SET_SASSLIST(state, res) {
      setLocalStrorage('SASSLIST', res)
      state.sassList = res
    },
    //设置加盟商信息
    SET_SAAS_CONFIG(state, res) {
      state.sassConfig = res
    },
    //设置corpId
    SET_CORPID(state, corpId) {
      state.corpId = corpId
    }
  },
  actions: {
    //登录过期处理
    resetToken({ commit, state }) {
      return new Promise((resolve, reject) => {
        commit('SER_TOKEN', {})
        commit('SET_USER_INFO', {})
        commit('SET_SASSLIST', [])
        resolve()
      })
    },
    // 获取分类
    getClassList({ commit, state }) {
      return new Promise((resolve, reject) => {
        getClassList().then(res => {
          commit('SET_CLASSLIST', res.data)
          resolve(res)
        })
      })
    },
    // 获取新token
    getNewToken({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (!state.token) {
          reject('no token')
          return
        }
        getNewToken({ refreshToken: TOKEN().refreshToken }).then(res => {
          commit('SER_TOKEN', res.data)
          resolve(res)
        })
      })
    },
    // 获取用户信息
    getUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {
        if (!state.token) {
          reject('no token')
          return
        }
        getUserInfo().then(res => {
          commit('SET_USER_INFO', res.data)
          resolve(res)
        })
      })
    },
    //获取用户字典
    getUserDict({ commit, state }) {
      return new Promise((resolve, reject) => {
        getUserDict().then(res => {
          commit('SET_URSER_DICT', res.data)
          resolve(res)
        })
      })
    },
    // 获取加盟商信息
    getsassConfig({ commit, state }) {
      return new Promise((resolve, reject) => {
        //建议测试加盟商不要修改此处 用hosts文件重定向下方便好用
        sassAuthDomain({
          domain: window.location.hostname
        }).then(res => {
          commit('SET_SAAS_CONFIG', res.data)
          commit('SET_CORPID', res.data.id)
          resolve(res.data)
        })
      })
    },
    // 开始倒计时
    codeTime({ commit, state }) {
      const copy = state.codeTime
      const interval = setInterval(() => {
        let codeTime = state.codeTime - 1
        if (state.codeTime === 0) {
          clearInterval(interval)
          codeTime = copy
        }
        commit('SET_CODETIME', codeTime)
      }, 1000)
    }
  },
  modules: {},
  getters
})
